import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  MenuItem,
  TextField,
  Snackbar,
  Portal,
  Tab,
  IconButton,
} from '@material-ui/core';

import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Alert from '../../../../component/Alert/index.js';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { useDialog100HoriStyles } from '../../../../views/Process/style.js';
import PropTypes from 'prop-types';
import { getAccount, updateAccount, resetPassword, getPermisstionGroup } from '../../../../services/api/User/index.js';
import { initAccount } from '../../../../constants/initial.js';
import TreeViewModal from '../../../../views/Process/TreeView/index.js';
import { getMenuLookupList } from '../../../../services/api/UserGroup/index.js';
import GeneralTable from '../../../../views/Process/Table/index.js';
import { Autocomplete } from '@material-ui/lab';
import { apiEndpoints } from '../../../../constants/apiEndpoints.js';
import { UPLOAD_OPEN } from '../../../../store/actions.js';
import { useDispatch } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ProfileModal = (props) => {
  const { openDialog, setOpenDialog, selectedDocument } = props;
  const [reload, setReloadList] = useState(false);
  const classes = useDialog100HoriStyles();
  const [tabIndex, setTabIndex] = React.useState('0');
  const dispatch = useDispatch();
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  const [menuLookupData, setMenuLookup] = useState([]);
  const { provinces: provinceList, genders: genderList } = useSelector((state) => state.metadata);
  const [account, setAccount] = React.useState({
    ...initAccount,
    ...selectedDocument,
  });
  const [userGroup, setUserGroup] = useState({});
  const [treeView, setTreeView] = useState({});

  useEffect(() => {
    if (!selectedDocument) return;
    const fetch = async () => {
      if (openDialog) {
        let data = await getAccount(selectedDocument.id);
        setAccount({ ...account, ...selectedDocument, ...data.data });
        setTreeView(data.treeview);
        data = await getPermisstionGroup(selectedDocument.id);
        setUserGroup(data.data.default_group);
        data = await getMenuLookupList(account.permission_group);
        setMenuLookup(data);
        setReloadList(true);
      }
    };
    if (openDialog) {
      fetch();
    }
  }, [openDialog]);

  useEffect(() => {
    if (!selectedDocument) return;
    setReloadList(true);
  }, [treeView]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDocumentToDefault();
    setAccount({
      ...initAccount,
    });
  };
  const [snackbarStatus, setSnackbarStatus] = useState({
    isOpen: false,
    type: '',
    text: '',
  });
  const handleOpenSnackbar = (isOpen, type, text) => {
    setSnackbarStatus({
      isOpen: isOpen,
      type: type,
      text: text,
    });
  };
  const handleUpdateAccount = async () => {
    try {
      if (account.employee_code === '' || account.email_address === '' || account.full_name === '') {
        handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
      } else {
        let check = await updateAccount({
          ...account,
          outputtype: 'RawJson',
        });
        if (check) {
          handleOpenSnackbar(true, 'success', 'Cập nhập thành công!');
          var user = JSON.parse(window.localStorage.getItem('user'));
          user.account = { ...user.account, avatar_url: account.image_url, fullname: account.full_name };
          console.log(user, 'proffile');
          localStorage.setItem('user', JSON.stringify(user));
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tài khoản đã tồn tại!');
        }
      }
    } catch (error) {
      handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
    } finally {
    }
  };
  const handleResetPasswordAccount = async () => {
    try {
      if (!account.new_password || !account.password || !account.new_password_confirm) {
        handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
      } else {
        if (account.new_password !== account.new_password_confirm) {
          handleOpenSnackbar(true, 'error', 'Mật khẩu mới không đúng!');
        } else {
          let check = await resetPassword(account.new_password, account.password, account.email_address);
          if (check) {
            handleOpenSnackbar(true, 'success', 'Cập nhập thành công!');
            handleCloseDialog();
          } else {
            handleOpenSnackbar(true, 'error', 'Cập nhật mật khẩu không thành công!');
          }
        }
      }
    } catch (error) {
      handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
    } finally {
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setAccount({
      ...account,
      [e.target.name]: value,
    });
  };

  const setDocumentToDefault = async () => {
    setTabIndex('0');
  };
  const openUploadDialog = () => {
    dispatch({ type: UPLOAD_OPEN, open: true, onSuccess: setURL });
  };
  const setURL = (image) => {
    setAccount({
      ...account,
      image_url: image,
    });
  };

  return (
    <React.Fragment>
      {snackbarStatus.isOpen && (
        <Portal>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackbarStatus.isOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
          >
            <Alert
              onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
              severity={snackbarStatus.type}
              sx={{ width: '100%' }}
            >
              {snackbarStatus.text}
            </Alert>
          </Snackbar>
        </Portal>
      )}

      <Grid container>
        <Dialog
          open={openDialog || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          className={classes.dialog}
          fullScreen
        >
          <DialogTitle style={{ background: 'none' }} disableTypography className={classes.dialogTitle}>
            <Typography variant="h4">Thông tin cá nhân</Typography>
            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ background: '#f0f2f8' }}>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <TabContext value={tabIndex}>
                <TabList
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  orientation="vertical"
                  variant="scrollable"
                  className={classes.tabPanel}
                >
                  <Tab label="Thông tin cơ bản" value="0" />
                  <Tab label="Thay đổi mật khẩu" value="2" />
                  <Tab label="Cơ cấu tổ chức" value="1" />
                  <Tab label="Phân quyền" value="3" />
                </TabList>
                <TabPanel value="0" className={classes.tab}>
                  <Grid container spacing={1} style={{ padding: 25 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <div>
                            <img
                              src={account.image_url}
                              alt=""
                              height={150}
                              width={150}
                              style={{
                                borderRadius: 20,
                                boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
                              }}
                            />
                          </div>
                          <div>
                            <Button style={{ color: '#2596be' }} onClick={openUploadDialog}>
                              Ảnh đại diện
                            </Button>
                            <div>Cập nhật ảnh đại diện</div>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" gutterBottom>
                                Họ và tên: {account.full_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" gutterBottom>
                                Email: {account.email_address}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" gutterBottom>
                                Mã nhân viên: {account.employee_code}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" gutterBottom>
                                Số ĐT: {account.phone_number}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Mã nhân viên(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            disabled
                            variant="outlined"
                            placeholder="Nhập mã nhân viên"
                            name="employee_code"
                            value={account.employee_code || ''}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Họ và tên(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Nhập họ và tên"
                            name="full_name"
                            value={account.full_name || ''}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Ngày sinh(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Nhập ngày sinh"
                            type="date"
                            name="dob"
                            value={account.dob}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Email(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Nhập email"
                            name="email_address"
                            type="email"
                            value={account.email_address || ''}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            SĐT(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Nhập số điện thoại"
                            name="phone_number"
                            value={account?.phone_number}
                            className={classes.inputField}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Giới tính(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="gender_id"
                            select
                            value={account.gender_id || ''}
                            onChange={handleChange}
                          >
                            <MenuItem value="0">Không chọn</MenuItem>
                            {genderList &&
                              genderList.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.value}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Tỉnh(*):
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            fullWidth
                            options={provinceList}
                            getOptionLabel={(option) => option.value}
                            value={provinceList.find((item) => item.id === account.province_id) || null}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Tỉnh" />}
                            onChange={(event, newValue) => {
                              setAccount({
                                ...account,
                                province_id: newValue?.id,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                          <Typography variant="body1" gutterBottom>
                            Địa chỉ(*):
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Nhập địa chỉ"
                            name="address"
                            value={account?.address}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid container spacing={2} className={classes.dialogButton}>
                          <Grid item lg={1} md={1} xs={1}>
                            <Button variant="outlined" onClick={() => handleCloseDialog()}>
                              Đóng
                            </Button>
                          </Grid>
                          <Grid item lg={10} md={10} xs={10}></Grid>
                          <Grid item lg={1} md={1} xs={1}>
                            <Button variant="contained" color="primary" onClick={() => handleUpdateAccount()}>
                              {'Cập nhật'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="1" className={classes.tab}>
                  <Grid container spacing={2} style={{ padding: 25 }}>
                    <Grid item lg={6} md={6} xs={6}>
                      <Typography variant="body1" gutterBottom style={{ color: 'black' }}>
                        Phòng ban
                      </Typography>
                      <TreeViewModal readOnly={true} treeData={treeView} />
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}>
                      <Typography variant="body1" gutterBottom style={{ color: 'black' }}>
                        Nhân viên trực thuộc
                      </Typography>
                      <GeneralTable
                        url={apiEndpoints.get_profile_cache_by_account_id}
                        queries={{ account_id: account.account_id }}
                        reload={reload}
                        setReloadList={setReloadList}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2" className={classes.tab}>
                  <Grid container spacing={2} justifyContent="flex-end" style={{ padding: 25 }}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            Mật khẩu cũ (*):
                          </Typography>
                          <TextField
                            fullWidth
                            type="password"
                            variant="outlined"
                            name="password"
                            value={account.password || ''}
                            size="small"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            Mật khẩu mới(*):
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="new_password"
                            type="password"
                            value={account.new_password || ''}
                            size="small"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item lg={12} md={12} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            Nhập lại mật khẩu mới(*):
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="new_password_confirm"
                            type="password"
                            value={account.new_password_confirm || ''}
                            size="small"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    <Grid container spacing={2} className={classes.dialogButton}>
                      <Grid item lg={1} md={1} xs={1}>
                        {/* <Button variant="outlined" onClick={() => handleCloseDialog()}>
                          Đóng
                        </Button> */}
                      </Grid>
                      <Grid item lg={10} md={10} xs={10}></Grid>
                      <Grid item lg={1} md={1} xs={1}>
                        <Button variant="contained" color="primary" onClick={() => handleResetPasswordAccount()}>
                          {'Cập nhật'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3" className={classes.tab}>
                  <Grid container spacing={2} style={{ padding: 25 }}>
                    <Typography variant="body1" gutterBottom style={{ color: 'black' }}>
                      Nhóm phân quyền: {userGroup?.group_name}
                    </Typography>
                    <div style={{ maxHeight: 700, minHeight: 700, marginTop: 20 }}>
                      <TreeViewModal
                        readOnly={true}
                        checkSelected={true}
                        urlGetData={apiEndpoints.get_menu_item_tree_view}
                        queries={''}
                        reload={reload}
                        listSelected={menuLookupData}
                      />
                    </div>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default ProfileModal;
