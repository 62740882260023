import config from '../config/config.js';

export const roleActions = {
  list_active_role: config.company + '_ORGANISATION_' + config.process + '_OPEN_ROLE_TEMPLATE_DEACTIVE_LIST',
  list_inactive_role: config.company + '_ORGANISATION_' + config.process + '_OPEN_ROLE_TEMPLATE_ACTIVE_LIST',
};
export const processroleActions = {
  list_tree: config.company + '_ORGANISATION_' + config.process + '_OPEN_ROLE_LIST',
};
export const accountActions = {
  list_active_user: 'MVN_ORGANISATION_ACCOUNT_OPEN_USER_LIST',
};
export const departmentActions = {
  list_active_department: 'MVN_ORGANISATION_ACCOUNT_OPEN_DEPARTMENT_LIST',
};
export const departmentDeactiveActions = {
  list_inactive_department: config.company + '_ORGANISATION_' + config.process + '_OPEN_DEPARTMENT_DEACTIVE_LIST',
};
export const usergroupAction = {
  list: 'MVN_ORGANISATION_USERGROUP_OPEN_USERGROUP_LIST',
};
export const usergroupItemAction = {
  list_role_item: 'MVN_ORGANISATION_PERMISSION_OPEN_PERMISSION_LIST',
};
export const hashtagCategoryAction = {
  list: 'MYM_HASHTAGSUB_DEFAULT_OPEN_TAG_CATEGORY_LIST',
};
export const hashtagAction = {
  list: 'MYM_HASHTAGSUB_DEFAULT_OPEN_TAG_LIST',
};

export const questionAction = {
  list: 'MYM_QNASUB_HOME_OPEN_QUESTION_LIST',
};
export const mentorcacheAction = {
  list: 'MYM_MENTORMENTEESUB_HOME_OPEN_MENTOR_LIST',
};
export const qnaCategoryAction = {
  list: 'MYM_QNASUB_HOME_OPEN_CATEGORY_LIST',
};
export const creditPackageAction = {
  list: 'MYM_CREDITSUB_HOME_OPEN_CREDIT_PACKAGE_LIST',
};
export const creditWalletAction = {
  list: 'MYM_CREDITSUB_HOME_OPEN_CREDIT_WALLET_LIST',
};

export const bookingAction = {
  list: 'MYM_BOOKING_COUNSELING_OPEN_COUNSELLING_CAREER_LIST',
};
export const bookingHighAction = {
  list: 'MYM_BOOKING_COUNSELING_OPEN_COUNSELLING_HIGH_SCHOOL_LIST',
};
export const bookingHighNewAction = {
  list: 'MYM_BOOKING_COUNSELING_OPEN_COUNSELLING_NEW_HIGH_SCHOOL_LIST',
};
export const orderAction = {
  list: 'MYM_BOOKING_COUNSELING_ORDER_BOOKING_LIST',
};
export const questionPriceAction = {
  list: 'MYM_QNASUB_HOME_OPEN_PRICE_LIST',
};
export const menteeAction = {
  list: 'MYM_MENTORMENTEESUB_HOME_OPEN_MENTEE_LIST',
};
export const bannerAction = {
  list: 'MYM_BANNERSUB_HOME_OPEN_BANNER',
};
export const bannerListAction = {
  list: 'MYM_BANNERSUB_HOME_OPEN_BANNERLIST',
};
export const livestreamAction = {
  list: 'MYM_EVENTSUB_HOME_OPEN_LIVESTREAM_LIST',
};
export const calendarAction = {
  list: 'MYM_BOOKING_HOME_OPEN_CALENDAR',
};
export const bookingDashBoard = {
  list: 'MYM_BOOKING_HOME_OPEN_BOOKING_DASHBOARD',
};
export const loginChartAction = {
  list: 'MYM_MENTOR_SUB_HOME_LOGIN_DASHBOARD',
};
export const registerChartAction = {
  list: 'MYM_MENTORSUB_HOME_OPEN_REGISTER_DASHBOARD',
};
export const emailBroadcastAction = {
  list: 'MYM_BROADCASTSUB_HOME_OPEN_EMAIL_LIST',
};
export const emailTemplateAction = {
  list: 'MYM_BROADCASTSUB_HOME_OPEN_EMAIL_TEMPLATE_LIST',
};
export const landingPageAction = {
  list: 'MYM_SITE_HOME_OPEN_LANDING_PAGE_LIST',
};
export const newsAction = {
  list: 'MYM_SITE_HOME_OPEN_NEWS_LIST',
};
export const playlistActions = {
  list: 'MYM_MEDIA_PODCAST_OPEN_PLAYLIST_LIST',
};
export const podcastActions = {
  list: 'MYM_MEDIA_PODCAST_OPEN_PODCAST_LIST',
};
export const episodeActions = {
  list: 'MYM_MEDIA_PODCAST_OPEN_EPISODE_LIST',
};
export const newsCategoryActions = {
  list: 'MYM_SITE_HOME_OPEN_NEWS_CATEGORY',
};
