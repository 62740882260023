import config from '../config/config.js';

export const view = {
  floating: {},

  user: {
    list: {
      create: 'MVN_ORGANISATION_ACCOUNT_CREATE_NEW_ACCOUNT_MENU_BUTTON',
    },
    detail: {
      save: 'MVN_ORGANISATION_ACCOUNT_SAVE_FORM_BUTTON',
    },
  },
  department: {
    list: {
      create: 'MVN_ORGANISATION_DEPARTMENT_CREATE_NEW_DEPARTMENT_MENU_BUTTON',
      update: 'MVN_ORGANISATION_DEPARTMENT_UPDATE_MENU_BUTTON',
      adduser: 'MVN_ORGANISATION_ACCOUNT_ASSIGN_USER_TO_DEPARTMENT_MENU_BUTTON',
      removeaccount: 'MVN_ORGANISATION_DEPARTMENT_REMOVE_USER_FROM_DEPARTMENT_MENU_BUTTON',
      deactive: 'MVN_ORGANISATION_DEPARTMENT_DEACTIVE_MENU_BUTTON',
    },
    detail: {
      save: 'MVN_ORGANISATION_DEPARTMENT_SAVE_FORM_BUTTON',
    },
  },
  role: {
    list: {
      create: config.company + '_ORGANISATION_' + config.process + '_ROLE_TEMPLATE_CREATE_MENU_BUTTON',
    },
    detail: {},
  },
  processrole: {
    list: {
      create: config.company + '_ORGANISATION_' + config.process + '_CREATE_NEW_PROCESS_ROLE_MENU_BUTTON',
      update: config.company + '_ORGANISATION_' + config.process + '_UPDATE_PROCESS_ROLE_MENU_BUTTON',
      update_dept_role: config.company + '_ORGANISATION_' + config.process + '_UPDATE_DEPARTMENT_ROLE_MENU_BUTTON',
      adduser: config.company + '_ORGANISATION_' + config.process + '_ADD_ACCOUNT_ROLE_MENU_BUTTON',
      adddept: config.company + '_ORGANISATION_' + config.process + '_ADD_DEPT_ROLE_MENU_BUTTON',
      removeaccount: config.company + '_ORGANISATION_' + config.process + '_REMOVE_ACCOUNT_ROLE_MENU_BUTTON',
      removedept: config.company + '_ORGANISATION_' + config.process + '_REMOVE_DEPT_ROLE_MENU_BUTTON',
      syncRole: config.company + '_ORGANISATION_' + config.process + '_SYNC_DEPARTMENT_ROLE_MENU_BUTTON',
    },
  },
  ugroup: {
    list: {
      create: `MVN_ORGANISATION_USERGROUP_CREATE_NEW_USER_GROUP_LIST`,
    },
    detail: {
      update: 'MVN_ORGANISATION_USERGROUP_SAVE_FORM_BUTTON',
    },
  },
  hashtagCategory: {
    list: {
      create: 'MYM_HASHTAGSUB_HOME_TAG_CATEGORY_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_HASHTAGSUB_HOME_TAG_CATEGORY_SAVE_FORM_BUTTON',
    },
  },
  hashtag: {
    list: {
      create: 'MYM_HASHTAGSUB_HOME_TAG_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_HASHTAGSUB_HOME_TAG_SAVE_FORM_BUTTON',
    },
  },

  qnaCategory: {
    list: {
      create: 'MYM_QNASUB_HOME_CREATE_NEW_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_QNASUB_HOME_UPDATE_FORM_BUTTON',
    },
  },
  creditPackage: {
    list: {
      create: 'MYM_CREDITSUB_HOME_CREDIT_PACKAGE_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_CREDITSUB_HOME_CREDIT_PACKAGE_SAVE_FORM_BUTTON',
    },
  },
  creditWallet: {
    list: {
      assign: 'MYM_CREDITSUB_HOME_CREDIT_WALLET_ASSIGN_MENU_BUTTON',
    },
  },

  booking: {
    list: {
      cancel: '',
      approve: '',
      note: '',
    },
    detail: {
      save: '',
      change_timeslot: 'MYM_BOOKING_HOME_CHANGE_TIMESLOT_FORM_BUTTON',
    },
  },
  order: {
    list: {
      save: '',
    },
    detail: {
      process: 'MYM_SALES_HOME_PROCESS_PAYMENT_FORM_BUTTON',
    },
  },
  qna: {
    list: {
      create: 'MYM_QNASUB_HOME_CREATE_NEW_QUESTION',
    },
    detail: {
      save: 'MYM_QNASUB_HOME_SAVE_FORM_BUTTON',
    },
  },
  mentor: {
    list: {
      create: 'MYM_PARTNER_MENTOR_MENTOR_CREATE_MENU_BUTTON',
      importData: 'MYM_PARTNER_MENTOR_IMPORT_DATA_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_PARTNER_MENTOR_MENTOR_SAVE_FORM_BUTTON',
      workday: 'MYM_PARTNER_MENTOR_WORKDAY_FORM_BUTTON',
      vacation: 'MYM_PARTNER_MENTOR_VACATION_FORM_BUTTON',
      generate_timeslot: 'MYM_PARTNER_MENTOR_MENTOR_GENERATE_TIMESLOT_FORM_BUTTON',
    },
  },
  questionPrice: {
    detail: {
      save: 'MYM_QNASUB_HOME_PRICE_SAVE_FORM_BUTTON',
    },
  },
  banner: {
    list: {
      create: 'MYM_SITE_HOME_BANNER_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_SITE_HOME_BANNER_SAVE_FORM_BUTTON',
    },
  },
  bannerList: {
    list: {
      create: 'MYM_SITE_HOME_BANNERLIST_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_SITE_HOME_BANNERLIST_SAVE_FORM_BUTTON',
    },
  },
  livestream: {
    list: {
      create: 'MYM_MARKETING_EVENT_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_MARKETING_EVENT_SAVE',
    },
  },
  emailBroadcast: {
    list: {
      create: 'EMAIL_BROADCAST_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'EMAIL_BROADCAST_SAVE_FORM_BUTTON',
    },
  },
  emailTemplate: {
    list: {
      create: 'EMAIL_TEMPLATE_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'EMAIL_TEMPLATE_SAVE_FORM_BUTTON',
    },
  },
  landingPage: {
    list: {
      create: 'MYM_SITE_HOME_LANDING_PAGE_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_SITE_HOME_LANDING_PAGE_SAVE_FORM_BUTTON',
    },
  },
  news: {
    list: {
      create: 'MYM_SITE_HOME_NEWS_CREATE_MENU_BUTTON',
      sync: 'MYM_SITE_HOME_NEWS_SYNC_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_SITE_HOME_NEWS_SAVE_FORM_BUTTON',
    },
  },
  playlist: {
    list: {
      create: 'MYM_MEDIA_PODCAST_PLAYLIST_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_MEDIA_PODCAST_PLAYLIST_SAVE_FORM_BUTTON',
    },
  },
  podcast: {
    list: {
      create: 'MYM_MEDIA_PODCAST_PODCAST_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_MEDIA_PODCAST_PODCAST_SAVE_FORM_BUTTON',
    },
  },
  episode: {
    list: {
      create: 'MYM_MEDIA_PODCAST_EPISODE_CRETAE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_MEDIA_PODCAST_EPISODE_SAVE_FORM_BUTTON',
    },
  },
  newsCategory: {
    list: {
      create: 'MYM_SITE_HOME_NEWS_CATEGORY_CREATE_MENU_BUTTON',
    },
    detail: {
      save: 'MYM_SITE_HOME_NEWS_CATEGORY_SAVE_FORM_BUTTON',
    },
  },
};
