export const userAvatar = 'https://obs.multicampus.vn/wp-content/uploads/2019/01/avatar.png';
export const imageDefault = 'https://obs.multicampus.vn/wp-content/uploads/2019/01/avatar.png';
export const imageBanner =
  'https://firebasestorage.googleapis.com/v0/b/huongnghiepnhanh.appspot.com/o/Document%2FImage%2Fbackground.png?alt=media&token=e1cc029f-6c35-4589-892d-f44970815322';

export const iconDefault =
  'https://firebasestorage.googleapis.com/v0/b/tqtapp-873d6.appspot.com/o/Icon%2Fsetting.png?alt=media&token=d3717224-a809-4347-8ff4-67d242be6835';

export const iconWhite =
  'https://firebasestorage.googleapis.com/v0/b/mavin-e7a45.appspot.com/o/Icon%2Fsetting.svg?alt=media&token=90d47c1a-e2d8-43d7-95f6-ae4d9bacb23f';
export const initAccount = {
  current_school: '',
  degree_id: '',
  dob: '1/1/2018',
  email_address: '@gmail.com',
  full_name: ' ',
  gender_id: '',
  image_url:
    'https://firebasestorage.googleapis.com/v0/b/huongnghiepnhanh.appspot.com/o/Avatar%20Nam.jpg?alt=media&token=8208326e-faa9-4bdf-b811-a48481839cb5',
  is_active: true,
  job_title: '',
  major: '',
  phone_number: '',
  province_id: '',
  password: '',
  id: '',
  address: '',
  permission_group: '',
  user_group_list: [],
};

export const initMentorData = {
  description: '',
  image_url: imageDefault,
  banner_url: imageBanner,
  fb_url: '',
  linkedin_url: '',
  address: '',
  gender_id: '',
  province_id: '',
  is_certified: false,
  workday: [],
  vacation: [],
  counselling_category1_id: '',
  counselling_category2_id: '',
  counselling_category3_id: '',
  career_category1_id: '',
  career_category2_id: '',
  career_category3_id: '',
  counselling_topic1_id: [],
  counselling_topic2_id: [],
  counselling_topic3_id: [],
  order_number: 0,
  is_active: true,
  file_path: '',
  dob: '',
  university_id: '',
  email_address: '',
  phone_number: '',
};

export const initWorkingDay = {
  weekday_list: [],
  from_hour: '',
  to_hour: '',
  is_active: true,
  id: '',
  applicable_to_date: '',
  applicable_from_date: '',
};

export const initVacationDay = {
  weekday_list: [],
  from_hour: '',
  to_hour: '',
  is_active: true,
  id: '',
  applicable_to_date: '',
  applicable_from_date: '',
};
export const dateOfWeek = [
  {
    label: 'Thứ 2',
    value: 'Monday',
  },
  {
    label: 'Thứ 3',
    value: 'Tuesday',
  },
  {
    label: 'Thứ 4',
    value: 'Wednesday',
  },
  {
    label: 'Thứ 5',
    value: 'Thursday',
  },
  {
    label: 'Thứ 6',
    value: 'Friday',
  },
  {
    label: 'Thứ 7',
    value: 'Saturday',
  },
  {
    label: 'Chủ nhật',
    value: 'Sunday',
  },
];

export const timeWorking = [
  {
    label: '08:00',
    value: '8',
  },
  {
    label: '09:00',
    value: '9',
  },
  {
    label: '10:00',
    value: '10',
  },
  {
    label: '11:00',
    value: '11',
  },
  {
    label: '12:00',
    value: '12',
  },
  {
    label: '13:00',
    value: '13',
  },
  {
    label: '14:00',
    value: '14',
  },
  {
    label: '15:00',
    value: '15',
  },
  {
    label: '16:00',
    value: '16',
  },
  {
    label: '17:00',
    value: '17',
  },
  {
    label: '18:00',
    value: '18',
  },
  {
    label: '19:00',
    value: '19',
  },
  {
    label: '20:00',
    value: '20',
  },
  {
    label: '21:00',
    value: '21',
  },
  {
    label: '22:00',
    value: '22',
  },
];

export const workingType = [
  {
    label: 'Hàng tuần',
    value: 'Hàng tuần',
  },
];
export const initNotification = {
  title: '',
  is_active: true,
  created_by: '',
  created_date: '',
};

export const initNotificationMessage = {
  title: '',
  is_active: true,
  created_date: '',
  banner_url: '',
  is_red_dot_on: true,
  is_actionable: true,
  body: '',
  id: '',
  action: {
    deeplink: '',
    title: '',
    image_url: '',
    label: '',
    link: '',
    object_id: '',
    object_type: '',
  },
  category_id: '',
  account_id: '',
};
export const initObjectType = [
  { id: 'PODCAST', value: 'Podcast' },
  {
    id: 'EPISODE',
    value: 'Episode',
  },
  {
    id: 'MENTOR',
    value: 'Mentor',
  },
  {
    id: 'EVENT',
    value: 'Event',
  },
  {
    id: 'VOUCHER',
    value: 'Voucher',
  },
  {
    id: 'OPEN_CATEGORY',
    value: 'QnA',
  },
];
