import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  TextField,
  Snackbar,
  Switch,
  FormControlLabel,
  Box,
  Tab,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TableRow,
} from '@material-ui/core';
import { apiEndpoints } from '../../../../constants';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import Alert from '../../../../component/Alert/index.js';
import { useSelector, useDispatch } from 'react-redux';
import { view } from '../../../../constants';
import useView from '../../../../hooks/useView';
import { useDialog50Styles } from '../../style.js';
import { getUserGroupList } from '../../../../services/api/UserGroup/index.js';
import { FLOATING_MENU_CHANGE, DOCUMENT_CHANGE } from '../../../../store/actions.js';
import { createAccount, updateAccount, getAllUser } from '../../../../services/api/User/index.js';
import { initAccount } from '../../../../constants';
import { getMenuLookupList } from '../../../../services/api/UserGroup/index.js';
import { NoPaddingAutocomplete } from '../../../../component/Autocomplete/index.js';
import { UPLOAD_OPEN } from '../../../../store/actions.js';
import TreeViewModal from '../../TreeView/index.js';
import GeneralTable from '../../Table';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AccountModal = () => {
  const classes = useDialog50Styles({ width: 50 });
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);
  const { form_buttons: formButtons } = useView();
  const buttonSave = formButtons.find((button) => button.name === view.user.detail.save);
  const [reload, setReloadList] = useState(false);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const { accountDocument: openDialog } = useSelector((state) => state.floatingMenu);
  const { selectedDocument } = useSelector((state) => state.document);
  const [_allUser, setAllUser] = useState([]);
  const { provinces: provinceList, genders: genderList } = useSelector((state) => state.metadata);
  const [permissionGroupList, setPermissionGroupList] = useState([]);
  const [userGroupList, setuserGroupList] = useState([]);
  const [menuLookupData, setMenuLookup] = useState([]);
  const [account, setAccount] = React.useState({
    ...initAccount,
  });
  useEffect(() => {
    if (!selectedDocument) return;
    setAccount({
      ...account,
      ...selectedDocument.data,
    });

    const fetch = async () => {
      let data = await getUserGroupList();
      setPermissionGroupList(data.list);
      setuserGroupList(data.user_group_list);
      setReloadList(true);
    };
    fetch();
  }, [selectedDocument]);
  useEffect(() => {
    const fetch = async () => {
      let data = await getAllUser();
      setAllUser(data);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      let data = await getMenuLookupList(account.permission_group);
      setMenuLookup(data);
    };
    fetch();
  }, [account.permission_group]);
  const handleCloseDialog = () => {
    setDocumentToDefault();
    setAccount({
      ...initAccount,
    });
    dispatch({ type: FLOATING_MENU_CHANGE, accountDocument: false });
  };
  const [snackbarStatus, setSnackbarStatus] = useState({
    isOpen: false,
    type: '',
    text: '',
  });
  const handleOpenSnackbar = (isOpen, type, text) => {
    setSnackbarStatus({
      isOpen: isOpen,
      type: type,
      text: text,
    });
  };

  const handleUpdateAccount = async () => {
    try {
      if (account.employee_code === '' || account.email_address === '' || account.full_name === '') {
        handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
      } else if (!account.id) {
        let check = await createAccount({
          ...account,
          outputtype: 'RawJson',
        });
        if (check) {
          handleOpenSnackbar(true, 'success', 'Tạo mới thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'account' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tài khoản đã tồn tại!');
        }
      } else {
        let check = await updateAccount({
          ...account,
          outputtype: 'RawJson',
        });
        if (check) {
          handleOpenSnackbar(true, 'success', 'Cập nhập thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'account' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tài khoản đã tồn tại!');
        }
      }
    } catch (error) {
      handleOpenSnackbar(true, 'error', 'Vui lòng điền đầy đủ thông tin!');
    } finally {
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setAccount({
      ...account,
      [e.target.name]: value,
    });
  };

  const setDocumentToDefault = async () => {
    setTabIndex(0);
  };

  const setURL = (image) => {
    setAccount({
      ...account,
      image_url: image,
    });
  };

  const openUploadDialog = () => {
    dispatch({ type: UPLOAD_OPEN, open: true, onSuccess: setURL });
  };

  return (
    <React.Fragment>
      {snackbarStatus.isOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarStatus.isOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
        >
          <Alert
            onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
            severity={snackbarStatus.type}
            sx={{ width: '100%' }}
          >
            {snackbarStatus.text}
          </Alert>
        </Snackbar>
      )}
      <Grid container>
        <Dialog
          open={openDialog || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          className={classes.dialog}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h4">Thông tin người dùng</Typography>

            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <TabContext value={tabIndex}>
              <TabList onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
                <Tab label="Thông tin cơ bản" value={0} />
                <Tab label="Phân quyền" value={1} />
                <Tab label="Nhóm người dùng" value={2} />
                <Tab label="Phòng ban" value={3} />
              </TabList>
              <TabPanel value={0}>
                <Grid container spacing={1}>
                  <Grid item xs={4} style={{ border: 'dashed 2px #c8c8c8' }}>
                    <Box className={classes.itemFile} onClick={openUploadDialog}>
                      <img src={account.image_url} alt="" className={classes.iconUpload} />
                      <Typography className={classes.fileName}>
                        <span onClick={() => openUploadDialog()}>Tải File lên</span>{' '}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Mã nhân viên(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="TextField"
                      name="employee_code"
                      value={account.employee_code || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Mã nhân viên(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="TextField"
                      name="full_name"
                      value={account.full_name || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Ngày sinh(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="TextField"
                      type="date"
                      name="dob"
                      value={account.dob}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Email(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="TextField"
                      name="email_address"
                      type="email"
                      value={account.email_address || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Email(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Nhập email"
                      name="email_address"
                      type="email"
                      value={account.email_address || ''}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      SĐT:
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Nhập số điện thoại"
                      name="phone_number"
                      value={account?.phone_number}
                      className={classes.inputField}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Giới tính:
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="TextField"
                      name="gender_id"
                      select
                      value={account.gender_id || ''}
                      onChange={handleChange}
                    >
                      <MenuItem value="0">Không chọn</MenuItem>
                      {genderList &&
                        genderList.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Tỉnh:
                    </Typography>
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      fullWidth
                      options={provinceList}
                      getOptionLabel={(option) => option.value}
                      value={provinceList.find((item) => item.id === account.province_id) || null}
                      renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Tỉnh" />}
                      onChange={(event, newValue) => {
                        setAccount({
                          ...account,
                          province_id: newValue?.id,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Địa chỉ:
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Nhập địa chỉ"
                      name="address"
                      value={account?.address}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Chức danh(*):
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Nhập chức danh"
                      name="major"
                      value={account?.major}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Nhóm quản lý trực tiếp:
                    </Typography>
                    <NoPaddingAutocomplete
                      options={userGroupList}
                      getOptionLabel={(option) => option.value}
                      value={userGroupList.find((item) => item.id === account.line_manager_id) || ''}
                      fullWidth
                      onChange={(e, value) => setAccount({ ...account, line_manager_id: value.id })}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={account?.is_active || false}
                          color="primary"
                          onChange={(e) => setAccount({ ...account, is_active: e.target.checked })}
                        />
                      }
                      labelPlacement="start"
                      label="Hoạt động"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={1}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Nhóm phân quyền:
                    </Typography>
                    <NoPaddingAutocomplete
                      options={permissionGroupList}
                      getOptionLabel={(option) => option.value}
                      value={permissionGroupList.find((item) => item.id === account.permission_group) || ''}
                      fullWidth
                      onChange={(e, value) => setAccount({ ...account, permission_group: value.id })}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <TreeViewModal
                      readOnly={true}
                      checkSelected={true}
                      urlGetData={apiEndpoints.get_menu_item_tree_view}
                      queries={''}
                      reload={true}
                      listSelected={menuLookupData}
                      setReloadList={setReloadList}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table className={classes.table} stickyHeader size={'medium'}>
                        <TableHead>
                          <TableCell align="left">Mã</TableCell>
                          <TableCell align="left">Nhóm người dùng</TableCell>
                        </TableHead>
                        <TableBody>
                          {selectedDocument?.user_group_list?.map((item) => (
                            <>
                              <TableRow>
                                <TableCell>{item.deparment_code}</TableCell>
                                <TableCell>{item.group_name}</TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TreeViewModal readOnly={true} treeData={selectedDocument?.treeview} />
                  </Grid>
                  <Grid item xs={6}>
                    <GeneralTable
                      url={apiEndpoints.get_profile_cache_by_account_id}
                      queries={{ account_id: selectedDocument?.account_id }}
                      reload={reload}
                      setReloadList={setReloadList}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Button variant="outlined" onClick={() => handleCloseDialog()}>
                  Đóng
                </Button>
              </Grid>
              <Grid item>
                {!account.id && (
                  <Button variant="contained" color="primary" onClick={() => handleUpdateAccount()}>
                    {'Tạo mới'}
                  </Button>
                )}
                {buttonSave && (
                  <Button variant="contained" color="primary" onClick={() => handleUpdateAccount()}>
                    {buttonSave.text}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default AccountModal;
