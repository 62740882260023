import React from 'react';
import { makeStyles, AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import { drawerWidth } from './../../constants';
import Header from '../MainLayout/Header/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    /*padding: theme.spacing(3),*/
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    // padding: '20px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  headerRoot: {
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #F2F2F2',
    transition: theme.transitions.create('width'),
    '& .MuiToolbar-regular': {
      gap: '20px',
      padding: '0 40px 0 50px',
    },
  },
}));

const ChatLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.headerRoot}>
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.main}>{children}</div>
      </main>
    </div>
  );
};

export default ChatLayout;
