export const apiEndpoints = {
  authenticate: '/Primary/?FlowAlias=bs_api_user_authenticate&action=api',
  get_project_list: '/Primary/?FlowAlias=bs_menu_api_get_project_list&action=api',
  get_app_list: '/Primary/?FlowAlias=bs_menu_api_get_list_app&action=api',
  get_app_setting_list: '/Primary/?FlowAlias=bs_menu_api_get_list_app_setting&action=api',
  get_folders: '/Primary/?FlowAlias=bs_menu_api_get_menu_tree&action=api',
  get_metadata: '/Primary/?FlowAlias=bs_api_mym_get_meta_data&action=api',
  get_menu_item_tree_view: '/Primary/?FlowAlias=bs_api_ui_get_menu_item_tree_view_by_company_code&action=api',
  get_menu_item_lookup_tree_view: '/Primary/?FlowAlias=bs_api_ui_get_project_item_lookup_list_by_user_group&action=api',
  update_menu_item_update_lookup:
    '/Primary/?FlowAlias=bs_api_ui_update_menu_project_item_list_by_user_group&action=api',
  // Account
  get_all_active_account: '/Primary/?FlowAlias=bs_user_api_get_all_active_account_by_page&action=api',
  get_all_deactive_account: '/Primary/?FlowAlias=bs_user_api_get_all_deactive_account_by_page&action=api',
  get_account_detail: '/Primary/?FlowAlias=bs_api_user_get_account_by_id&action=api',
  create_account: '/Primary/?FlowAlias=bs_api_user_create_account_by_host&action=api',
  update_account: '/Primary/?FlowAlias=bs_api_user_update_account_by_id&action=api',
  get_all_account_by_department_and_role_template:
    '/Primary/?FlowAlias=bs_api_user_get_all_acount_by_departmentand_role_template&action=api',
  assign_account_to_dept: '/Primary/?FlowAlias=bs_api_user_api_assign_account_to_department&action=api',
  remove_account_from_dept: '/Primary/?FlowAlias=bs_api_user_api_remove_account_from_department&action=api',
  active_account: '/Primary/?FlowAlias=bs_api_user_active_account&action=api',
  get_account_list_by_process_role: '/Primary/?FlowAlias=bs_api_user_get_list_by_process_role&action=api',
  get_all_task: '/Primary/?FlowAlias=bs_api_process_get_all_task_by_current_user&action=api',
  get_all_department_by_page: '/Primary/?FlowAlias=bs_api_dept_get_all_active_department_by_page&action=api',
  get_department_role_by_group: '/Primary/?FlowAlias=bs_api_dept_get_department_role_by_group_id&action=api',
  get_all_account_list: '/Primary/?FlowAlias=bs_api_orgm_user_get_all_account&action=api',
  reset_password: '/Primary/?FlowAlias=bs_api_orgm_user_reset_password&action=api',
  forgot_password: '/Primary/?FlowAlias=bs_api_user_forget_password&action=api',
  validate_forgot_password: '/Primary/?FlowAlias=bs_api_user_validate_forgot_password_verification&action=api',
  get_user_card_list: '/Primary/?FlowAlias=bs_api_user_get_card_list_by_company_code&action=api',
  get_profile_by_user_group_id: '/Primary/?FlowAlias=bs_api_orgm_get_profile_list_by_user_group_id&action=api',
  get_profile_cache_by_account_id: '/Primary/?FlowAlias=bs_api_user_get_user_cache_by_account_id&action=api',
  //Role template
  get_all_active_role_template:
    '/Primary/?FlowAlias=bs_api_role_template_get_all_active_role_template_by_page&action=api',
  active_role_template: '/Primary/?FlowAlias=bs_api_role_template_active_role_template&action=api',
  get_detail_role_template: '/Primary/?FlowAlias=bs_api_role_template_get_detail_role_template&action=api',
  get_all_inactive_role_template:
    '/Primary/?FlowAlias=bs_api_role_template_get_all_inactive_role_template_by_page&action=api',
  create_role_template: '/Primary/?FlowAlias=bs_api_role_template_create_role_template&action=api',
  update_role_template: '/Primary/?FlowAlias=bs_api_role_template_create_role_template&action=api',
  get_role_tree_data: '/Primary/?FlowAlias=bs_api_process_role_get_tree_data_role&action=api',
  get_all_role_template_by_department_code:
    '/Primary/?FlowAlias=bs_api_dept_get_role_template_by_department_code&action=api',
  get_option_role_template: '/Primary/?FlowAlias=bs_api_role_template_get_optinal_role&action=api',
  add_account_to_group: '/Primary/?FlowAlias=bs_api_user_add_acount_to_group&action=api',
  remove_account_to_group: '/Primary/?FlowAlias=bs_api_user_remove_account_from_group&action=api',
  create_process_role: '/Primary/?FlowAlias=bs_api_process_create_role&action=api',
  update_process_role: '/Primary/?FlowAlias=bs_api_process_update_role&action=api',
  add_user_depart_to_process_role: '/Primary/?FlowAlias=bs_api_process_add_dept_user&action=api',
  remove_user_from_process_role: '/Primary/?FlowAlias=bs_api_process_remove_user&action=api',
  sync_process_role: '/Primary/?FlowAlias=bs_api_process_sync_role_department&action=api',
  remove_dept_from_process_role: '/Primary/?FlowAlias=bs_api_process_remove_dept&action=api',
  get_role_detail: '/Primary/?FlowAlias=bs_api_process_role_get_role_detail_by_role_code&action=api',
  get_process_list: '/Primary/?FlowAlias=bs_get_process_by_app_code&action=api',

  //Department
  sync_group_for_department: '/Primary/?FlowAlias=bs_api_role_template_sync_group_for_department&action=api',
  get_tree_view_data: '/Primary/?FlowAlias=bs_api_dept_get_tree_view_data&action=api',
  deactive_department: '/Primary/?FlowAlias=bs_api_dept_deactive_department&action=api',
  create_department: '/Primary/?FlowAlias=bs_api_dept_create_department&action=api',
  update_department: '/Primary/?FlowAlias=bs_api_dept_update_department&action=api',
  get_department_list: '/Primary/?FlowAlias=bs_api_dept_get_department_list&action=api',
  get_department_type_list: '/Primary/?FlowAlias=bs_api_dept_get_department_type_list&action=api',
  get_department_detail: '/Primary/?FlowAlias=bs_api_dept_get_detail_department_by_name&action=api',
  get_dept_list_by_process_role: '/Primary/?FlowAlias=bs_api_dept_get_list_by_process_code&action=api',
  get_department_deactive_list: '/Primary/?FlowAlias=bs_api_dept_get_all_inactive_department_by_page&action=api',
  get_department_by_account_id:
    '/Primary/?FlowAlias=bs_api_department_get_treeview_department_by_account_id&action=api',

  //User Group
  get_user_group_list_by_page: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_list_by_page&action=api',
  get_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_detail&action=api',
  update_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_update_user_group&action=api',
  create_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_create_user_group&action=api',
  update_user_group_account: '/Primary/?FlowAlias=bs_api_orgm_user_update_permistion_group&action=api',
  get_user_group_list: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_list_by_company&action=api',
  get_user_group_project_list_by_page: '/Primary/?FlowAlias=bs_api_orgm_get_user_group_project_list_by_page&action=api',
  get_user_group_list_by_account: '/Primary/?FlowAlias=bs_api_ugroup_get_ugroup_list_by_account_id&action=api',
  set_hidden_user_group: '/Primary/?FlowAlias=bs_api_ugroup_set_active_user_group_by_group_code&action=api',

  // Hashtag Category
  get_hashtag_category_list_by_page: '/Primary/?FlowAlias=bs_api_share_get_hashtag_category_list_by_page&action=api',
  get_hashtag_category_detail: '/Primary/?FlowAlias=bs_api_share_get_hashtag_category_detail&action=api',
  create_hashtag_category: '/Primary/?FlowAlias=bs_api_share_create_hashtag_category&action=api',
  update_hashtag_category: '/Primary/?FlowAlias=bs_api_share_update_hashtag_category&action=api',
  set_active_hashtag_category: '/Primary/?FlowAlias=bs_api_share_set_active_hashtag_category&action=api',

  // Hashtag
  get_hashtag_list_by_page: '/Primary/?FlowAlias=bs_api_share_get_hashtag_list_by_page&action=api',
  get_hashtag_detail: '/Primary/?FlowAlias=bs_api_share_get_hashtag_detail&action=api',
  create_hashtag: '/Primary/?FlowAlias=bs_api_share_create_hashtag&action=api',
  update_hashtag: '/Primary/?FlowAlias=bs_api_share_update_hashtag&action=api',
  set_active_hashtag: '/Primary/?FlowAlias=bs_api_share_set_active_hashtag&action=api',

  get_all_tag: '/Primary/?FlowAlias=bs_api_share_get_tag_list_meta&action=api',

  //Question
  get_question_list_by_page: '/Primary/?FlowAlias=bs_api_booing_qna_get_question_list_by_filter&action=api',
  get_question_detail: '/Primary/?FlowAlias=bs_api_booking_qna_get_question_detail_by_id&action=api',
  set_featured_question: '/Primary/?FlowAlias=bs_api_booking_qna_set_question_featured&action=api',
  set_hidden_answer: '/Primary/?FlowAlias=bs_api_booking_qna_set_hidden_answer&action=api',
  create_new_question: '/Primary/?FlowAlias=bs_api_booking_qna_create_new_question&action=api',
  update_question: '/Primary/?FlowAlias=bs_api_booking_qna_update_qna&action=api',
  get_question_livestream: '/Primary/?FlowAlias=bs_api_booing_qna_get_question_list_by_livestream_category&action=api',
  set_visible_question: '/Primary/?FlowAlias=bs_api_booking_set_visible_question&action=api',
  set_order_question: '/Primary/?FlowAlias=bs_api_booking_qna_set_order_number_question&action=api',
  //Mentor Cache
  get_mentor_cache_list_by_page: '/Primary/?FlowAlias=bs_api_booking_qna_get_mentor_cache_list_by_page&action=api',
  get_mentor_cache_list_by_category: '/Primary/?FlowAlias=bs_api_qna_get_mentor_list_by_page_category&action=api',
  get_mentor_cache_detai: '/Primary/?FlowAlias=bs_api_booking_qna_get_mentor_cache_by_id&action=api',
  register_mentor_qna: '/Primary/?FlowAlias=bs_api_booking_register_mentor_qna_by_host&action=api',
  //Category
  get_qna_category_list_by_page: '/Primary/?FlowAlias=bs_api_qna_get_qna_category_list_by_page&action=api',
  get_qna_category_detail: '/Primary/?FlowAlias=bs_api_qna_get_category_detail_by_id&action=api',
  create_qna_category: '/Primary/?FlowAlias=bs_api_qna_create_new_category&action=api',
  update_qna_category: '/Primary/?FlowAlias=bs_api_qna_update_category_by_id&action=api',
  set_active_qna_category: '/Primary/?FlowAlias=bs_api_qna_set_active_category&action=api',
  get_qna_category_active_list: '/Primary/?FlowAlias=bs_api_share_meta_get_meta_data2&action=api',
  get_tag_list_by_category_id: '/Primary/?FlowAlias=bs_api_share_get_tag_list_meta&action=api',
  // Credit Package
  get_credit_package_list_by_page: '/Primary/?FlowAlias=bs_api_credit_get_credit_package_list_by_page&action=api',
  get_credit_package_detail: '/Primary/?FlowAlias=bs_api_credit_get_credit_package_detail&action=api',
  create_credit_package: '/Primary/?FlowAlias=bs_api_credit_create_credit_package&action=api',
  update_credit_package: '/Primary/?FlowAlias=bs_api_credit_update_credit_package&action=api',
  set_active_credit_package: '/Primary/?FlowAlias=bs_api_credit_set_active_credit_package&action=api',

  // Credit Wallet
  get_credit_wallet_list_by_page: '/Primary/?FlowAlias=bs_api_credit_get_credit_wallet_list_by_page&action=api',
  get_credit_wallet_detail: '/Primary/?FlowAlias=bs_api_credit_get_credit_wallet_detail&action=api',
  get_credit_wallet_log: '/Primary/?FlowAlias=bs_api_credit_get_credit_wallet_logs&action=api',
  assign_credit_wallet: '/Primary/?FlowAlias=bs_api_credit_assign_credit_wallet&action=api',

  get_booking_list: '/Primary/?FlowAlias=bs_api_booking_get_booking_list_by_page_and_filters&action=api',
  get_high_school_booking_list:
    '/Primary/?FlowAlias=bs_api_booking_get_high_school_counselling_list_by_page_and_filter&action=api',
  get_high_school_new_booking_list:
    '/Primary/?FlowAlias=bs_api_booking_get_high_school_new_counselling_list_by_page_and_filter&action=api',
  get_booking_detail: '/Primary/?FlowAlias=bs_api_counselling_get_counselling_detail&action=api',
  update_booking: '/Primary/?FlowAlias=bs_api_counselling_update_infor_counselling&action=api',
  cancel_counselling: '/Primary/?FlowAlias=bs_api_counselling_cancel_counselling_by_id&action=api',
  download_data: '/Primary/?FlowAlias=bs_api_booking_export_all_data&action=api',
  review_booking: '/Primary/?FlowAlias=bs_api_counselling_review_booking&action=api',
  set_note_booking: '/Primary/?FlowAlias=bs_api_counselling_update_note&action=api',
  update_new_time_slot: '/Primary/?FlowAlias=bs_api_counselling_update_new_timeslot_meeting&action=api',
  update_booking_mentor: '/Primary/?FlowAlias=bs_api_counselling_update_mentor_and_timeslot&action=api',
  get_feedback_detail: '/Primary/?FlowAlias=bs_api_counselling_get_feedback_detail&action=api',
  get_statistic_data: '/Primary/?FlowAlias=bs_api_get_booking_branches_dasboard_data&action=api',
  get_log_data: '/Primary/?FlowAlias=bs_api_counselling_get_log_activity_list_by_counselling_id&action=api',
  get_list_note: '/Primary/?FlowAlias=bs_api_counselling_get_note_list&action=api',
  get_full_calendar: '/Primary/?FlowAlias=bs_api_counselling_get_full_calendar&action=api',
  get_chat_box_by_booking_id: '/Primary/?FlowAlias=bs_api_get_chat_box_by_booking_id&action=api',
  get_mentor_list_by_category_id: '/Primary/?FlowAlias=bs_api_partner_get_all_mentor&action=api',
  get_mentor_detail: '/Primary/?FlowAlias=bs_api_private_partner_get_mentor_detail_by_id&action=api',
  get_order_detai: '/Primary/?FlowAlias=bs_api_sales_get_order_detail_by_id&action=api',
  get_prepaid_card_order: '/Primary/?FlowAlias=bs_api_sale_get_product_and_number_list_by_order_id&action=api',
  approve_pending_order: '/Primary/?FlowAlias=bs_api_sales_approve_pending_order_by_id&action=api',
  get_pending_booking_order_list: '/Primary/?FlowAlias=bs_api_sales_get_booking_order_pending_list_by_page&action=api',

  get_all_active_mentor: '/Primary/?FlowAlias=bs_api_partner_get_all_active_mentor&action=api',
  get_mentor_list_by_page: '/Primary/?FlowAlias=bs_api_partner_get_mentor_list_by_page_v2&action=api',
  create_mentor: '/Primary/?FlowAlias=bs_api_partner_create_mentor&action=api',
  update_mentor: '/Primary/?FlowAlias=bs_api_partner_update_mentor&action=api',
  set_active_mentor: '/Primary/?FlowAlias=bs_api_partner_set_active_mentor&action=api',
  generate_timeslot: '/Primary/?FlowAlias=bs_api_partner_generate_timeslot&action=api',
  set_featured_mentor: '/Primary/?FlowAlias=bs_api_partner_set_featured_mentor&action=api',
  get_timeslot_by_mentor_id: '/Primary/?FlowAlias=bs_api_inventory_get_timeslot_by_mentor_id&action=api',
  get_mentor_list: '/Primary/?FlowAlias=bs_api_partner_get_mentor_list&action=api',
  get_career_and_topic: '/Primary/?FlowAlias=bs_api_partner_get_career_and_topic&action=api',
  get_mentor_question: '/Primary/?FlowAlias=bs_api_partner_get_mentor_question&action=api',
  get_mentor_category: '/Primary/?FlowAlias=bs_api_partner_get_mentor_category&action=api',

  // Mentee
  get_list_mentee_by_page: '/Primary/?FlowAlias=bs_api_user_get_mentee_list_by_page&action=api',
  get_mentee_detail: '/Primary/?FlowAlias=bs_api_user_get_mentee_detail_by_id&action=api',
  get_user_profile_category_list: '/Primary/?FlowAlias=bs_api_user_get_list_user_profile_category&action=api',
  update_user_profile_category_for_mentee:
    '/Primary/?FlowAlias=bs_api_user_update_user_profile_category_for_mentee&action=api',

  // Price
  get_price_list_by_page: '/Primary/?FlowAlias=bs_api_booking_qna_get_price_list_by_page&action=api',
  get_price_detail: '/Primary/?FlowAlias=bs_api_booking_qna_get_price_detail_by_id&action=api',
  update_price: '/Primary/?FlowAlias=bs_api_booking_qna_update_price&action=api',
  //Banner
  get_banner_list: '/Primary/?FlowAlias=bs_api_site_banner_get_list_banner_active&action=api',
  get_banner_list_inactive: '/Primary/?FlowAlias=bs_api_site_banner_get_list_banner_inactive&action=api',
  get_banner_detai: '/Primary/?FlowAlias=bs_api_site_banner_get_banner_detail&action=api',
  create_new_banner: '/Primary/?FlowAlias=bs_api_site_banner_create_new_banner&action=api',
  update_banner: '/Primary/?FlowAlias=bs_api_site_banner_update_banner&action=api',
  set_active_banner: '/Primary/?FlowAlias=bs_api_site_banner_set_active_banner&action=api',
  get_all_banner: '/Primary/?FlowAlias=bs_api_site_banner_get_all_banner&action=api',

  // BannerList
  get_bannerlist_list: '/Primary/?FlowAlias=bs_api_site_banner_get_list_banner_by_page&action=api',
  get_bannelist_list_inactive: '/Primary/?FlowAlias=bs_api_site_banner_get_list_banner_inactive_by_page&action=api',
  get_bannerlist_detail: '/Primary/?FlowAlias=bs_api_site_banner_get_bannerlist_detail&action=api',
  create_new_bannerlist: '/Primary/?FlowAlias=bs_api_site_banner_create_new_bannerlist&action=api',
  update_bannerlist: '/Primary/?FlowAlias=bs_api_site_banner_update_bannerlist&action=api',

  get_list_object_id: '/Primary/?FlowAlias=bs_notification_api_get_list_object&action=api',
  add_banner_to_live: '/Primary/?FlowAlias=bs_api_site_banner_add_banner_to_livestream&action=api',

  // Livestream
  get_livestream_list: '/Primary/?FlowAlias=bs_api_marketing_get_list_livestream_by_page&action=api',
  get_livestream_detail: '/Primary/?FlowAlias=bs_api_marketing_private_get_event_detail&action=api',
  create_new_livestream: '/Primary/?FlowAlias=bs_api_marketing_create_livestream&action=api',
  update_livestream: '/Primary/?FlowAlias=bs_api_marketing_update_livestream&action=api',
  set_active_livestream: '/Primary/?FlowAlias=bs_api_marketing_active_event&action=api',

  //chat
  get_chat_box_list: '/Primary/?FlowAlias=bs_api_chat_get_chat_box_list_by_page&action=api',
  get_chat_message_list: '/Primary/?FlowAlias=bs_api_get_chat_message_list_by_chatbox_id&action=api',
  post_chat_message: '/Primary/?FlowAlias=bs_api_post_chat_message&action=api',
  zalo_send_message: '/Primary/?FlowAlias=bs_api_zalo_send_message&action=api',

  //Booking
  get_full_calendar: '/Primary/?FlowAlias=bs_api_counselling_get_full_calendar&action=api',
  //Chart
  get_line_chart_data: '/Primary/?FlowAlias=bs_api_get_line_chart_data&action=api',
  get_booking_data_by_career: '/Primary/?FlowAlias=bs_api_get_booking_data_by_career_for_chart&action=api',
  get_chart_register: '/Primary/?FlowAlias=bs_api_booking_get_chart_register_new_account&action=api',
  get_chart_login: '/Primary/?FlowAlias=bs_api_booking_get_chart_login&action=api',

  // Broadcast
  get_email_broadcast_list: '/Primary/?FlowAlias=bs_api_share_get_email_broadcast_list_by_page&action=api',
  get_broadcast_detail: '/Primary/?FlowAlias=bs_api_broadcast_get_broadcast_detail_by_id&action=api',
  create_broadcast: '/Primary/?FlowAlias=bs_api_broadcast_create_broadcast&action=api',
  update_broadcast: '/Primary/?FlowAlias=bs_api_broadcast_update_broadcast&action=api',
  get_category_and_channel: '/Primary/?FlowAlias=bs_api_broadcast_get_category_and_channel&action=api',

  // Email Template
  get_email_template_list: '/Primary/?FlowAlias=bs_api_share_get_email_template_list_by_page&action=api',
  get_email_template_detail: '/Primary/?FlowAlias=bs_api_share_get_email_template_detail_by_id&action=api',
  create_email_template: '/Primary/?FlowAlias=bs_api_share_create_email_template&action=api',
  update_email_template: '/Primary/?FlowAlias=bs_api_share_update_email_template&action=api',
  get_email_template_category: '/Primary/?FlowAlias=bs_api_get_email_template_category&action=api',

  // Landing Page
  get_landing_page_list: '/Primary/?FlowAlias=bs_api_site_get_landing_page_list_by_page&action=api',
  get_inactive_landing_page_list: '/Primary/?FlowAlias=bs_api_site_get_landing_page_list_inactive_by_page&action=api',
  get_landing_page_detail: '/Primary/?FlowAlias=bs_api_site_get_landing_page_detail_by_id&action=api',
  create_landing_page: '/Primary/?FlowAlias=bs_api_site_create_landing_page&action=api',
  update_landing_page: '/Primary/?FlowAlias=bs_api_site_update_landing_page&action=api',
  set_active_landing_page: '/Primary/?FlowAlias=bs_api_site_set_active_landing_page&action=api',

  // News
  get_news_list: '/Primary/?FlowAlias=bs_api_news_get_news_list_active_by_page&action=api',
  get_inactive_news_list: '/Primary/?FlowAlias=bs_api_news_get_news_list_inactive_by_page&action=api',
  get_news_detail: '/Primary/?FlowAlias=bs_api_news_get_news_detail_by_id&action=api',
  create_news: '/Primary/?FlowAlias=bs_api_news_create_news&action=api',
  update_news: '/Primary/?FlowAlias=bs_api_news_update_news&action=api',
  get_category: '/Primary/?FlowAlias=bs_api_news_get_category&action=api',
  set_active_news: '/Primary/?FlowAlias=bs_api_news_set_active_news&action=api',
  set_featured_news: '/Primary/?FlowAlias=bs_api_news_set_featured_news&action=api',
  search_published_news: '/Primary/?FlowAlias=bs_api_news_search_published_news_by_page&action=api',
  get_all_news: '/Primary/?FlowAlias=bs_api_news_get_all_news_by_page&action=api',
  sync_new: '/Primary/?FlowAlias=bs_api_news_sync_new&action=api',

  // Meida
  // // Playlist
  get_playlist_list: '/Primary/?FlowAlias=bs_media_api_get_list_playlist_active&action=api',
  get_playlist_detail: '/Primary/?FlowAlias=bs_media_api_get_playlist_detail_by_id&action=api',
  create_playlist: '/Primary/?FlowAlias=bs_media_api_create_new_playlist&action=api',
  update_playlist: '/Primary/?FlowAlias=bs_media_api_update_playlist&action=api',
  set_active_playlist: '/Primary/?FlowAlias=bs_media_api_set_active_playlist&action=api',
  // // Podcast
  get_podcast_list: '/Primary/?FlowAlias=bs_media_api_get_list_podcast_active&action=api',
  get_podcast_detail: '/Primary/?FlowAlias=bs_media_api_get_podcast_detail_by_id&action=api',
  create_podcast: '/Primary/?FlowAlias=bs_media_api_create_podcast&action=api',
  update_podcast: '/Primary/?FlowAlias=bs_media_api_update_podcast&action=api',
  set_featured_podcast: '/Primary/?FlowAlias=bs_media_api_set_featured_podcast&action=api',
  set_active_podcast: '/Primary/?FlowAlias=bs_media_api_set_active_podcast&action=api',

  get_counseling_categoies: '/Primary/?FlowAlias=bs_partner_api_get_counseling_categoies&action=api',
  // // Episode
  get_episode_list: '/Primary/?FlowAlias=bs_media_api_get_list_episode_active&action=api',
  get_episode_detail: '/Primary/?FlowAlias=bs_media_api_get_episode_detail_by_id&action=api',
  create_episode: '/Primary/?FlowAlias=bs_media_api_create_episode&action=api',
  update_episode: '/Primary/?FlowAlias=bs_media_api_update_episode&action=api',
  set_active_episode: '/Primary/?FlowAlias=bs_media_api_set_active_episode&action=api',

  // News Category
  get_news_category_list: '/Primary/?FlowAlias=bs_api_news_get_news_category_list_by_page&action=api',
  get_news_category_detail: '/Primary/?FlowAlias=bs_api_news_get_news_category_detail_by_id&action=api',
  create_news_category: '/Primary/?FlowAlias=bs_api_news_create_news_category&action=api',
  update_news_category: '/Primary/?FlowAlias=bs_api_news_update_news_category&action=api',
  set_active_news_category: '/Primary/?FlowAlias=bs_api_news_set_active_news_category&action=api',
  get_news_category_by_landing_page: '/Primary/?FlowAlias=bs_api_news_get_news_category_by_landing_page&action=api',
};
