import React, { useEffect } from 'react';
import { TreeView } from '@material-ui/lab';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeItemClassKey from '@material-ui/lab/TreeItem/TreeItem';
import './index.css';
import { Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import axiosInstance from '../../../services/axios';
async function getTreeData(url, queries) {
  return await axiosInstance.post(url, { ...queries }).then((response) => {
    if (response.status === 200 && response.data.return === 200) {
      const { list } = response.data;
      return list;
    }
    return [];
  });
}
const TreeViewModal = (props) => {
  const {
    treeData,
    documentType,
    setSelectedDepartment,
    openDetailTree,
    urlGetData,
    reload,
    setReloadList,
    queries,
    readOnly,
    checkSelected,
    listSelected,
  } = props;

  const [dataShow, setData] = React.useState();
  const fetch = async () => {
    console.log('reload');
    let data = await getTreeData(urlGetData, queries);
    setData(data);
  };

  useEffect(() => {
    !treeData ? fetch() : setData(treeData);
    setReloadList(false);
  }, [reload, listSelected]);

  const handleClickOpen = (data) => {
    switch (documentType) {
      case 'department':
        setSelectedDepartment({ department_code: data, change: true });
        break;
      default:
        break;
    }
  };
  const renderIcon = (id, label) => {
    return (
      <Box>
        {readOnly ? (
          <span>{label}</span>
        ) : (
          <>
            <span onClick={() => handleClickOpen(id)}>{label}</span>{' '}
            <span
              style={{ cursor: 'context-menu' }}
              onClick={() => {
                openDetailTree(id);
              }}
            >
              <Edit />
            </span>
          </>
        )}
      </Box>
    );
  };
  const renderItem = (data) => {
    let dataCheck = true;
    if (checkSelected) {
      dataCheck = listSelected.some((item) => item === data.id);
      console.log(dataCheck);
    }
    if (dataCheck)
      if (data.children.length === 0) {
        return (
          <TreeItem
            nodeId={data.id}
            label={renderIcon(data.id, data.name)}
            key={data.id}
            className={TreeItemClassKey.MuiTreeItemlabel}
          />
        );
      } else {
        return (
          <TreeItem nodeId={data.id} label={renderIcon(data.id, data.name)} key={data.id}>
            {data.children.map((data2) => renderItem(data2))}
          </TreeItem>
        );
      }
  };
  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }
  return (
    <React.Fragment>
      <div
        style={{
          maxHeight: 600,
          minHeight: 600,
          background: '#fff',
        }}
      >
        {dataShow && (
          <TreeView
            style={{
              minHeight: 600,
              background: '#fff',
              maxHeight: 600,
              overflowY: 'auto',
              padding: 5,
            }}
            aria-label="file system navigator"
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            sx={{ height: 264, flexGrow: 1, maxWidth: 200, overflowY: 'auto' }}
          >
            <>{dataShow.map((data) => renderItem(data))}</>
          </TreeView>
        )}
      </div>
    </React.Fragment>
  );
};

export default TreeViewModal;
