import * as actionTypes from './actions';

export const initialState = {
  folder: false,
  document: false,
  detailDocument: false,
  accountDocument: false,
  departmentDocument: false,
  processDeptDocument: false,
  processUserDocument: false,
  processrolecode: '',
  profileDocument: false,
  hashtagCategory: false,
  mentor: false,
  hashtag: false,
  question: false,
  order: false,
  questionCategory: false,
  qnaCategory: false,
  banner: false,
  bannerList: false,
  booking: false,
  creditPackage: false,
  creditWallet: false,
  assignCreditWallet: false,
  questionPrice: false,
  mentee: false,
  livestream: false,
  emailBroadcast: false,
  emailTemplate: false,
  landingPage: false,
  news: false,
  playlist: false,
  podcast: false,
  episode: false,
  newsCategory: false,
};

const floatingMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLOATING_MENU_CHANGE:
      return {
        ...state,
        folder: action.folder,
        document: action.document,
        detailDocument: action.detailDocument,
        accountDocument: action.accountDocument,
        profileDocument: action.profileDocument,
        departmentDocument: action.departmentDocument,
        processDeptDocument: action.processDeptDocument,
        processUserDocument: action.processUserDocument,
        processrolecode: action.processrolecode,
        hashtagCategory: action.hashtagCategory,
        hashtag: action.hashtag,
        banner: action.banner,
        booking: action.booking,
        bannerList: action.bannerList,
        question: action.question,
        questionCategory: action.questionCategory,
        mentor: action.mentor,
        qnaCategory: action.qnaCategory,
        creditPackage: action.creditPackage,
        creditWallet: action.creditWallet,
        assignCreditWallet: action.assignCreditWallet,
        order: action.order,
        questionPrice: action.questionPrice,
        mentee: action.mentee,
        livestream: action.livestream,
        emailBroadcast: action.emailBroadcast,
        emailTemplate: action.emailTemplate,
        landingPage: action.landingPage,
        news: action.news,
        playlist: action.playlist,
        podcast: action.podcast,
        episode: action.episode,
        newsCategory: action.newsCategory,
      };
    default:
      return state;
  }
};

export default floatingMenuReducer;
