import * as actionTypes from './actions';

const initialState = {
  open: false,
  folder: 'Upload',
  fileType: 'other',
  onSuccess: () => {},
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_OPEN:
      return {
        ...state,
        open: true,
        folder: action.folder ? action.folder : initialState.folder,
        fileType: action.fileType ? action.fileType : initialState.fileType,
        onSuccess: action.onSuccess ? action.onSuccess : initialState.onSuccess,
      };
    case actionTypes.UPLOAD_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default uploadReducer;
