import React from 'react';
import clsx from 'clsx';
import { makeStyles, useMediaQuery, useTheme, AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { drawerWidth } from './../../constants';
import Header from './Header';
import Sidebar from './Sidebar';
import FloatingMenu from '../../views/FloatingMenu';
import Loading from './Loading';
import useLoading from './../../hooks/useLoading';
import ConfirmPopup from '../../views/ConfirmPopup';
import AccountModal from '../../views/Process/Account/Detail';
import DepartmentModal from '../../views/Process/Department/Detail';
import UserGroupModal from '../../views/Process/UserGroup/Detail';
import UserGroupMenuItemModal from '../../views/Process/UserGroupMenuItem/Detail';
import config from '../../config/config.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    /*padding: theme.spacing(3),*/
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: `calc(-${drawerWidth}px + 90px )`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  main: {
    // padding: '20px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  headerRoot: {
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #F2F2F2',
    transition: theme.transitions.create('width'),
    width: `calc(100% - ${90}px)`,
    '& .MuiToolbar-regular': {
      gap: '20px',
      padding: '0 40px 0 50px',
    },
  },
  headerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { loading } = useLoading();

  const { documentType } = useSelector((state) => state.document);

  const renderDetailDialog = () => {
    switch (documentType) {
      case 'account':
        return <AccountModal />;
      case 'department':
        return <DepartmentModal />;
      case 'usergroup':
        return <UserGroupModal />;
      case 'usergroupmenuitem':
        return <UserGroupMenuItemModal />;
      default:
        return null;
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [matchUpMd]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.headerRoot, { [classes.headerOpen]: drawerOpen })}>
          <Toolbar>
            <Header />
          </Toolbar>
        </AppBar>
        <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
        <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
          <div className={classes.toolbar} />
          <div className={classes.main}>{children}</div>
          <FloatingMenu />
          {renderDetailDialog()}
        </main>
        {loading && <Loading />}
        <ConfirmPopup />
      </div>
      {/* <div>
        <center style={{ color: 'black', marginLeft: 150, marginTop: 20 }}>
          (C) {new Date().getFullYear()}, Bản quyền thuộc {config.title}
        </center>
      </div> */}
    </>
  );
};

export default MainLayout;
