import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB8ByfJZNfdbAXADhRHHr-Is3nMxQq2iEs',
  authDomain: 'truebpm-30e33.firebaseapp.com',
  projectId: 'truebpm-30e33',
  storageBucket: 'truebpm-30e33.appspot.com',
  messagingSenderId: '229239226459',
  appId: '1:229239226459:web:fd5ed4af61185a9795bf7e',
  measurementId: 'G-SE6L75T7Z5',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
