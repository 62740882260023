import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  MenuItem,
  TextField,
  Snackbar,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { PersonAddOutlined } from '@material-ui/icons';
import { TabContext } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useView from '../../../../hooks/useView';
import CloseIcon from '@material-ui/icons/Close';
import { useDialog100Styles } from '../../style';
import { FLOATING_MENU_CHANGE, DOCUMENT_CHANGE } from '../../../../store/actions';
import Alert from '../../../../component/Alert';
import { apiEndpoints, view } from '../../../../constants';
import CustomizedDialogs from '../../DialogSelected';
import { assignAccount, removeAccount } from '../../../../services/api/User';
import {
  getDepartmentList,
  getDepartmentTypeList,
  createDepartment,
  updateDepartment,
} from '../../../../services/api/Department';
import GeneralTable from '../../Table';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DepartmentModal = () => {
  const classes = useDialog100Styles();
  const dispatch = useDispatch();
  const [reload, setReloadList] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { form_buttons: formButtons } = useView();
  const [openDialogUser, setOpenDiaglogUser] = useState(false);
  const buttonSave = formButtons.find((button) => button.name === view.department.detail.save);
  const { departmentDocument: openDialog } = useSelector((state) => state.floatingMenu);
  const { selectedDocument } = useSelector((state) => state.document);
  const [department, setDepartment] = React.useState({
    department_code: null,
    department_description: '',
    department_name: '',
    department_type: '',
    is_active: true,
    parent_department_code: '',
    optional_role_template: [],
  });
  const [snackbarStatus, setSnackbarStatus] = useState({
    isOpen: false,
    type: '',
    text: '',
  });
  const [categories, setCategory] = React.useState();
  const [departmentTypes, setDepartmentType] = React.useState();
  const handleAssignAccount = async (email) => {
    const email_address_list = email.map((item) => item.email_address);
    await assignAccount({ department_code: department.department_code, email_address_list: email_address_list });
    setReloadList(true);
    handleOpenSnackbar(true, 'success', 'Thêm người dùng thành công!');
  };
  const handleRemoveAccount = async (object) => {
    await removeAccount({ department_code: department.department_code, email_address: object.email_address });
    setReloadList(true);
    handleOpenSnackbar(true, 'success', 'Xoá người dùng thành công!');
  };
  useEffect(() => {
    if (openDialog) {
      getDepartmentParent({
        parent_department_code: null,
      });
    }
    if (!selectedDocument) return;
    setDepartment({
      ...selectedDocument,
    });
    setReloadList(true);
  }, [selectedDocument, openDialog]);

  const getDepartmentParent = async (company_code, parent_department_code) => {
    try {
      let departmentData = await getDepartmentTypeList(company_code);
      setDepartmentType(departmentData);
      let categoriesData = await getDepartmentList(company_code, parent_department_code);
      setCategory(categoriesData);
    } catch {}
  };
  const handleCloseDialog = () => {
    setDocumentToDefault();
    setReloadList(false);
    setDepartment({
      department_code: null,
      department_description: '',
      department_name: '',
      department_type: 'COMPANY',
      is_active: true,
      parent_department_code: '',
      optional_role_template: [],
    });

    dispatch({ type: FLOATING_MENU_CHANGE, departmentDocument: false });
  };

  const handleUpdateDepartment = async () => {
    try {
      if (department.department_code == null) {
        let check = await createDepartment({
          ...department,
          outputtype: 'RawJson',
        });

        if (check) {
          handleOpenSnackbar(true, 'success', 'Tạo mới thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'department' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tạo mới thất bại!');
        }
      } else {
        let check = await updateDepartment({
          ...department,
          outputtype: 'RawJson',
        });

        if (check) {
          handleOpenSnackbar(true, 'success', 'Cập nhật thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'department' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Cập nhật thất bại!');
        }
      }
    } catch (error) {
      console.log('error update department', error);
    } finally {
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setDepartment({
      ...department,
      [e.target.name]: value,
    });
  };

  const setDocumentToDefault = async () => {
    setTabIndex(0);
  };
  const handleOpenSnackbar = (isOpen, type, text) => {
    setSnackbarStatus({
      isOpen: isOpen,
      type: type,
      text: text,
    });
  };

  return (
    <React.Fragment>
      {openDialogUser && (
        <CustomizedDialogs open={openDialogUser} setOpen={setOpenDiaglogUser} handleSubmit={handleAssignAccount} />
      )}

      {snackbarStatus.isOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarStatus.isOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
        >
          <Alert
            onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
            severity={snackbarStatus.type}
            sx={{ width: '100%' }}
          >
            {snackbarStatus.text}
          </Alert>
        </Snackbar>
      )}
      <Grid container>
        <Dialog
          open={openDialog || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          className={classes.dialog}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h4">Thông tin phòng ban</Typography>

            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <TabContext value={tabIndex}>
              <TabPanel value={tabIndex} index={0}>
                <Grid container spacing={1}>
                  <Grid item lg={4} xs={4} md={4}>
                    <Grid container spacing={1}>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Tên phòng ban(*):
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="department_name"
                          placeholder="TextField"
                          onChange={handleChange}
                          value={department.department_name || ''}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Trực thuộc(*):
                        </Typography>
                        <TextField
                          name="parent_department_code"
                          select
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={department.parent_department_code}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Không chọn</em>
                          </MenuItem>
                          {categories &&
                            categories.map((category) => (
                              <MenuItem
                                value={category.Key}
                                key={category.Key}
                                selected={category.Key === department.parent_department_code}
                              >
                                {category.Value}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Loại (*):
                        </Typography>
                        <TextField
                          name="department_type"
                          select
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={department.department_type}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Không chọn</em>
                          </MenuItem>
                          {departmentTypes &&
                            departmentTypes.map((category) => (
                              <MenuItem
                                value={category.Key}
                                key={category.Key}
                                selected={category.Key === department.department_type}
                              >
                                {category.Value}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Mô tả:
                        </Typography>
                        <TextField
                          multiline
                          rows={3}
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder="TextField"
                          value={department.department_description}
                          onChange={(event) =>
                            setDepartment({ ...department, department_description: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={department?.is_active || false}
                              color="primary"
                              onChange={(e) => setDepartment({ ...department, is_active: e.target.checked })}
                            />
                          }
                          labelPlacement="start"
                          label="Hoạt động"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={8} xs={8} md={8}>
                    <Grid container justifyContent="space-between">
                      <Grid item></Grid>
                      <Grid item>
                        {' '}
                        <Button
                          startIcon={
                            <>
                              <PersonAddOutlined />
                            </>
                          }
                          variant="outlined"
                          onClick={() => setOpenDiaglogUser(true)}
                          style={{ marginBottom: 5 }}
                        >
                          Thêm người dùng
                        </Button>
                      </Grid>
                    </Grid>
                    <GeneralTable
                      url={apiEndpoints.get_all_account_by_department_and_role_template}
                      queries={{ department_code: selectedDocument?.department_code }}
                      reload={reload}
                      setReloadList={setReloadList}
                      removeObject={handleRemoveAccount}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Button variant="outlined" onClick={() => handleCloseDialog()}>
                  Đóng
                </Button>
              </Grid>
              <Grid item>
                {!selectedDocument && (
                  <Button variant="contained" color="primary" onClick={() => handleUpdateDepartment()}>
                    {'Tạo mới'}
                  </Button>
                )}
                {buttonSave && (
                  <Button variant="contained" color="primary" onClick={() => handleUpdateDepartment()}>
                    {buttonSave.text}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default DepartmentModal;
