import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid } from '@material-ui/core';
import { NoPaddingAutocomplete } from '../../../component/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { getAllUser } from '../../../services/api/User';
const DialogStyle = makeStyles((theme) => ({
  dialog: {
    '&>div:nth-child(3)': {
      '&>div': {
        width: '500px !important',
        maxWidth: '500px !important',
        maxHeight: '400px !important',
      },
    },
  },
}));

export default function CustomizedDialogs(props) {
  const { open, setOpen, handleSubmit } = props;
  const [allUser, setAllUser] = React.useState([]);
  const [SelectedList, setSelected] = React.useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    const fetch = async () => {
      let data = await getAllUser();
      setAllUser(data);
    };
    fetch();
  }, []);
  const handleClick = () => {
    handleSubmit(SelectedList);
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        className={DialogStyle().dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title">Cập nhật người dùng</DialogTitle>
        <DialogContent>
          <NoPaddingAutocomplete
            options={allUser}
            getOptionLabel={(option) => option.email_address}
            fullWidth
            multiple
            onChange={(e, value) => setSelected(value)}
            size="small"
            renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button autoFocus variant="outlined" onClick={handleClose}>
                Đóng
              </Button>
            </Grid>
            <Grid item>
              <Button autoFocus color="primary" variant="contained" onClick={handleClick}>
                Thêm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
