import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/core/styles';

export const NoPaddingAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    padding: '6px 12px !important',
    '& .MuiChip-root': {
      '& .MuiChip-deleteIcon ': {
        color: 'white !important',
      },
    },
  },
});
