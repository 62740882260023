import {
  Card,
  ClickAwayListener,
  CardContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { AppsOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: 4,
    },
  },
  listRoot: {
    padding: 0,
  },
  cardRoot: {
    width: 320,
  },
  cardTitle: {
    fontSize: 15,
    fontWeight: 600,
    padding: '0 10px',
    marginBottom: 10,
  },
  cardContent: {
    padding: 10,

    '&:last-child': {
      paddingBottom: 15,
    },
  },
  listItem: {
    padding: '0 10px',
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
}));

const PopoverApp = (props) => {
  const classes = useStyles();
  const { open, anchorEl, onClose } = props;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.popover}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} color="textSecondary">
              Apps
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <AppsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Mavin Apps 1" />
                </ListItem>
              </Grid>
              <Grid item xs={6}>
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <AppsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Mavin Apps 2" />
                </ListItem>
              </Grid>
              <Grid item xs={6}>
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <AppsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Mavin Apps 3" />
                </ListItem>
              </Grid>
              <Grid item xs={6}>
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <AppsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Mavin Apps 4" />
                </ListItem>
              </Grid>
              <Grid item xs={6}>
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <AppsOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Mavin Apps 5" />
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Popover>
  );
};

export default PopoverApp;
