import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import vi from 'date-fns/locale/vi';

import App from './layout/App';
import reducer from './store/reducer';
import config from './config/config';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';

const syncConfig = {
  whitelist: [],
};
const middlewares = [createStateSyncMiddleware(syncConfig)];
const store = createStore(reducer, {}, applyMiddleware(...middlewares));
// initStateWithPrevTab(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
