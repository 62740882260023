import axiosServices from '../../axios';
import { apiEndpoints } from '../../../constants';

export const getDepartmentDetail = async (department_code, setView) => {
  return axiosServices
    .post(apiEndpoints.get_department_detail, {
      outputtype: 'RawJson',
      department_code,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { data: news, view } = response.data;
        setView({ ...view, action: 'detail' });
        return news;
      } else return {};
    });
};
export const getDepartmentByAccount = async () => {
  return axiosServices
    .post(apiEndpoints.get_department_by_account_id, {
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { treeview } = response.data;

        return treeview;
      } else return {};
    });
};
export const getDepartmentList = async (department) => {
  return axiosServices
    .post(apiEndpoints.get_department_list, {
      outputtype: 'RawJson',
      ...department,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: news } = response.data;

        return news;
      } else return {};
    });
};
export const getDepartmentTypeList = async (department) => {
  return axiosServices
    .post(apiEndpoints.get_department_type_list, {
      outputtype: 'RawJson',
      ...department,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: news } = response.data;

        return news;
      } else return {};
    });
};
export const getDataTreeView = async () => {
  return axiosServices
    .post(apiEndpoints.get_tree_view_data, {
      outputtype: 'RawJson',
    })

    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: news } = response.data;
        return news;
      } else return {};
    });
};

export const createDepartment = async (department) => {
  return axiosServices.post(apiEndpoints.create_department, department).then((response) => {
    if (response.status === 200 && response.data.return === 200) return true;
    return false;
  });
};
export const activeDepartment = async (department) => {
  return axiosServices
    .post(apiEndpoints.deactive_department, {
      outputtype: 'RawJson',
      ...department,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const updateDepartment = async (department) => {
  return axiosServices.post(apiEndpoints.update_department, department).then((response) => {
    if (response.status === 200 && response.data.return === 200) return true;
    return false;
  });
};

export const getDeptListByProcessRole = async (process_role_code, page, no_item_per_page) => {
  return axiosServices
    .post(apiEndpoints.get_dept_list_by_process_role, {
      outputtype: 'RawJson',
      page: page,
      process_role_code: process_role_code,
      no_item_per_page: no_item_per_page,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: news } = response.data;
        return news;
      } else return {};
    });
};
export const getAllDepartment = async () => {
  return axiosServices.post(apiEndpoints.get_all_department_by_page, {}).then((response) => {
    if (response.status === 200 && response.data.return === 200) {
      const { list } = response.data;
      return list;
    } else return {};
  });
};

export const getDepartmentRoleList = async (group_id) => {
  return axiosServices
    .post(apiEndpoints.get_department_role_by_group, {
      outputtype: 'RawJson',
      group_id,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return {};
    });
};

export const getOptionalRoleList = async (department_type_code) => {
  return axiosServices
    .post(apiEndpoints.get_option_role_template, {
      outputtype: 'RawJson',
      department_type_code: department_type_code,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return {};
    });
};
