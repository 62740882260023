import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Snackbar,
  FormControlLabel,
  Switch,
  Box,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TabContext } from '@material-ui/lab';
import Alert from '../../../../component/Alert/index.js';
import { useSelector, useDispatch } from 'react-redux';
import { apiEndpoints, view } from '../../../../constants';
import useView from '../../../../hooks/useView';
import { PersonAddOutlined } from '@material-ui/icons';
import { useDialog50Styles } from '../../style.js';
import { FLOATING_MENU_CHANGE, DOCUMENT_CHANGE } from '../../../../store/actions.js';
import { Autocomplete } from '@material-ui/lab';
import { createUserGroupDetail, updateUserGroupDetail } from '../../../../services/api/UserGroup/index';
import { assignAccountToGroup, removeAccountFromGroup } from '../../../../services/api/User/index.js';
import CustomizedDialogs from '../../DialogSelected/index.js';
import PropTypes from 'prop-types';

import { getAllDepartment } from '../../../../services/api/Department/index.js';
import GeneralTable from '../../Table/index.js';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const UserGroupModal = () => {
  const classes = useDialog50Styles({ width: 50 });
  const dispatch = useDispatch();
  const [reload, setReloadList] = useState(false);
  const [openDialogUser, setOpenDiaglogUser] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const { form_buttons: formButtons } = useView();
  const buttonSave = formButtons.find((button) => button.name === view.ugroup.detail.update);
  const { detailDocument: openDialog } = useSelector((state) => state.floatingMenu);
  const { selectedDocument } = useSelector((state) => state.document);
  const [usergroup, setUserGroup] = useState({
    department_code: '',
    group_name: '',
    is_active: true,
    is_permission_group: false,
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState({});
  useEffect(() => {
    const fetch = async () => {
      let data = await getAllDepartment();
      setDepartmentList(data);
      if (!selectedDocument) return;
      setUserGroup({ ...selectedDocument });
      setReloadList(true);
      let dataFind = data.find((item) => item.department_code === selectedDocument?.deparment_code);
      if (!!dataFind) setDepartmentSelected(dataFind);
    };
    fetch();
  }, [selectedDocument]);
  const handleAssignAccount = async (account) => {
    const account_id = account.map((item) => item.account_id);
    await assignAccountToGroup({ account_id: account_id, id: selectedDocument?.id });
    setReloadList(true);
    handleOpenSnackbar(true, 'success', 'Thêm người dùng thành công');
  };
  const handleRemoveAccount = async (object) => {
    await removeAccountFromGroup({ account_id: object.account_id, id: selectedDocument?.id });
    setReloadList(true);
    handleOpenSnackbar(true, 'success', 'Xoá người dùng thành công');
  };
  const handleCloseDialog = () => {
    setDocumentToDefault();
    dispatch({ type: FLOATING_MENU_CHANGE, detailDocument: false });
  };
  const [snackbarStatus, setSnackbarStatus] = useState({
    isOpen: false,
    type: '',
    text: '',
  });
  const handleOpenSnackbar = (isOpen, type, text) => {
    setSnackbarStatus({
      isOpen: isOpen,
      type: type,
      text: text,
    });
  };
  const handleUpdateAccount = async () => {
    try {
      if (!selectedDocument) {
        let check = await createUserGroupDetail(usergroup);
        if (check) {
          handleOpenSnackbar(true, 'success', 'Tạo mới thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'usergroup' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tạo mới lỗi!');
        }
      } else {
        let check = await updateUserGroupDetail(usergroup);
        if (check) {
          handleOpenSnackbar(true, 'success', 'Cập nhập thành công!');
          dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'usergroup' });
          handleCloseDialog();
        } else {
          handleOpenSnackbar(true, 'error', 'Tạo mới lỗi!');
        }
      }
    } catch (error) {
      handleOpenSnackbar(true, 'error', 'Tạo mới lỗi!');
    } finally {
    }
  };

  const handleChange = (e) => {
    setUserGroup({ ...usergroup, [e.target.name]: e.target.value });
  };

  const setDocumentToDefault = async () => {
    setDepartmentSelected({});
    dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null, documentType: 'usergroup' });
    setTabIndex(0);
  };

  return (
    <React.Fragment>
      {openDialogUser && (
        <CustomizedDialogs open={openDialogUser} setOpen={setOpenDiaglogUser} handleSubmit={handleAssignAccount} />
      )}

      {snackbarStatus.isOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarStatus.isOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
        >
          <Alert
            onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
            severity={snackbarStatus.type}
            sx={{ width: '100%' }}
          >
            {snackbarStatus.text}
          </Alert>
        </Snackbar>
      )}
      <Grid container>
        <Dialog
          open={openDialog || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          className={classes.dialog}
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography variant="h4">Thông tin nhóm người dùng</Typography>
            <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <TabContext value={tabIndex}>
              <TabPanel value={tabIndex} index={0}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Phòng ban:
                        </Typography>
                        <Autocomplete
                          options={departmentList}
                          value={departmentSelected}
                          blurOnSelect={true}
                          size="small"
                          onChange={(e, value) => {
                            setDepartmentSelected(value);
                            setUserGroup({
                              ...usergroup,
                              group_name: value.department_name + '_',
                              department_code: value.department_code,
                            });
                          }}
                          getOptionLabel={(option) => option.department_name}
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Tên:
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder="TextField"
                          name="group_name"
                          value={usergroup?.group_name}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={usergroup.is_active || false}
                              color="primary"
                              onChange={(e) => setUserGroup({ ...usergroup, is_active: e.target.checked })}
                            />
                          }
                          labelPlacement="start"
                          label="Hoạt động"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={usergroup.is_permission_group || false}
                              color="primary"
                              onChange={(e) => setUserGroup({ ...usergroup, is_permission_group: e.target.checked })}
                            />
                          }
                          labelPlacement="start"
                          label="Nhóm phân quyền"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} md={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item></Grid>
                      <Grid item>
                        {' '}
                        <Button
                          startIcon={
                            <>
                              <PersonAddOutlined />
                            </>
                          }
                          variant="outlined"
                          onClick={() => setOpenDiaglogUser(true)}
                          style={{ marginBottom: 5 }}
                        >
                          Thêm người dùng
                        </Button>
                      </Grid>
                    </Grid>
                    <GeneralTable
                      url={apiEndpoints.get_profile_by_user_group_id}
                      queries={{ id: selectedDocument?.id }}
                      reload={reload}
                      setReloadList={setReloadList}
                      removeObject={handleRemoveAccount}
                    />
                    {/* <TableContainer>
                      <Table className={classes.table} stickyHeader size={'medium'}>
                        <TableHead>
                          <TableCell align="left">Ảnh</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Họ tên</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableHead>

                        <TableBody>
                          {usergroup?.user_list?.map((item) => (
                            <TableRow>
                              <TableCell>
                                <img className={classes.tableUserAvatar} src={item.image_url} />
                              </TableCell>
                              <TableCell>{item.email_address}</TableCell>
                              <TableCell>{item.full_name}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    setOpenRemove({ open: true, email_address: item.account_id });
                                  }}
                                >
                                  <RemoveCircle />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer> */}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Button variant="outlined" onClick={() => handleCloseDialog()}>
                  Đóng
                </Button>
              </Grid>
              <Grid item>
                {!selectedDocument && (
                  <Button variant="contained" color="secondary" onClick={() => handleUpdateAccount()}>
                    {'Tạo mới'}
                  </Button>
                )}
                {buttonSave && (
                  <Button variant="contained" color="primary" onClick={() => handleUpdateAccount()}>
                    {buttonSave.text}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};

export default UserGroupModal;
