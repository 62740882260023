import React from 'react';
import { makeStyles, useMediaQuery, useTheme, Drawer, Tooltip, Typography } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import MenuList from './MenuList';
import { drawerWidth } from './../../../constants';
import { AppsOutlined } from '@material-ui/icons';
import PopoverApp from './PopoverApp/index.js';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  toolbar: theme.mixins.toolbar,
  logoContainer: {
    lineHeight: 0,
    background: theme.palette.primary.main,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  drawerPaperLight: {
    // width: drawerWidth,
    overflowY: 'inherit',
    borderRight: 'none',
    boxShadow: '0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15)',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
    transition: 'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important',
  },
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  ScrollHeight: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    position: 'relative',
  },
  openDrawer: {
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: drawerWidth,
      },
    },
  },
  closeDrawer: {
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: '90px',
        transform: 'unset !important',
        visibility: 'unset !important',
      },
    },
  },
  toggle: {
    cursor: 'pointer',
    background: '#E8EAEC',
    position: 'absolute',
    top: '72px',
    right: '-12px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      fontSize: '10px',
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: 4,
    },
  },
  listRoot: {
    padding: 0,
  },
  cardRoot: {
    width: 320,
  },
  cardTitle: {
    fontSize: 15,
    fontWeight: 600,
    padding: '0 10px',
    marginBottom: 10,
  },
  cardContent: {
    padding: 10,

    '&:last-child': {
      paddingBottom: 15,
    },
  },
  listItem: {
    padding: '0 10px',
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
  app: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    padding: 10,
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '& > *': {
      color: '#fff',
    },
  },
  appOpen: {
    justifyContent: 'flex-start',
    gap: 10,
  },
  appIcon: {
    fontSize: '1.4rem',
  },
}));

const MainLayout = (props) => {
  const { drawerOpen, drawerToggle, window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.ScrollHeight}>
        <div className={clsx(classes.app, { [classes.appOpen]: drawerOpen })} onClick={handleClick}>
          <AppsOutlined className={classes.appIcon} />
          {drawerOpen && (
            <Typography noWrap variant="h6">
              Ứng dụng
            </Typography>
          )}
        </div>
        <PopoverApp anchorEl={anchorEl} open={open} onClose={handleClose} />
        <MenuList drawerOpen={drawerOpen} drawerToggle={drawerToggle} />
        <Tooltip title={drawerOpen ? 'Đóng menu' : 'Mở menu'} placement="right">
          <div className={classes.toggle} onClick={drawerToggle}>
            {drawerOpen ? <DoubleArrowIcon className={classes.rotate} /> : <DoubleArrowIcon />}
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        className={drawerOpen ? classes.openDrawer : classes.closeDrawer}
        classes={{ paper: classes.drawerPaperLight }}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default MainLayout;
