import React from 'react';
import { Grid, Typography, Button, CardMedia, makeStyles } from '@material-ui/core';
import Error from './../../../assets/svgs/comingsoon.svg';
const useStyles = makeStyles((theme) => ({
  errortitle: {
    fontSize: '25px',
    fontWeight: '600',
    position: 'relative',
    marginBottom: '15px',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 'calc(50% - 25px)',
      width: '50px',
      height: '4px',
      background: theme.palette.primary.main,
      borderRadius: '3px',
    },
  },
  authwrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    minWidth: '100%',
    minHeight: '100vh',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
  },
  errorimg: {
    width: '80%',
    margin: '0 auto',
  },
}));
const Errors = () => {
  const classes = useStyles();

  return (
    <div className={classes.authwrapper}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item lg={4} md={6} sm={10}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CardMedia component="img" image={Error} title="Cover image" className={classes.errorimg} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="h6" className={classes.errortitle}>
                Có lỗi xảy ra
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="body2">
                Vui lòng thử lại sau hoặc liên hệ với quản trị viên
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.assign(window.location.origin)}
              >
                Quay lại trang chủ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Errors;
