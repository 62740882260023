import React from 'react';
import { Grid, Typography, Link, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  errortext: {
    fontSize: '140px',
    fontWeight: '900',
    position: 'relative',
    lineHeight: '120px',
    color: theme.palette.primary.main,
    filter: 'drop-shadow(2px 6px 0px rgba(0,0,0,0.2))',
  },
  errortitle: {
    fontSize: '25px',
    fontWeight: '600',
    position: 'relative',
    marginBottom: '15px',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-15px',
      left: 'calc(50% - 25px)',
      width: '50px',
      height: '4px',
      background: theme.palette.primary.main,
      borderRadius: '3px',
    },
  },
  authwrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    minWidth: '100%',
    minHeight: '100vh',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
  },
}));
const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.authwrapper}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item lg={4} md={6} sm={10}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="div" variant="h6" className={classes.errortext}>
                404
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="h6" className={classes.errortitle}>
                Không tìm thấy trang
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="body2">
                Bạn có thể quay lại <Link href="/">Trang chủ</Link> để tìm kiếm
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
