import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, Typography, useTheme, Popper, Fade, Paper, List, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';
import {
  SELECTED_APP_CHANGE,
  PROJECT_CHANGE,
  FOLDER_CHANGE,
  SELECTED_FOLDER_CHANGE,
} from '../../../../../store/actions';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import clsx from 'clsx';
import { iconDefault, iconWhite } from '../../../../../constants/initial.js';

const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: '20px',
    alignSelf: 'stretch',
    color: '#fff !important',
  },
  listItemroot: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 10px',
    position: 'relative',
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#000',
      '& $noWrap': {
        color: theme.palette.primary.main,
      },
      '& $icon': {
        filter:
          'brightness(0) saturate(100%) invert(41%) sepia(38%) saturate(7488%) hue-rotate(195deg) brightness(91%) contrast(102%);',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#000',

      '& $noWrap': {
        color: theme.palette.primary.main,
      },
      '& $icon': {
        filter:
          'brightness(0) saturate(100%) invert(41%) sepia(38%) saturate(7488%) hue-rotate(195deg) brightness(91%) contrast(102%);',
      },
    },
  },
  listItem: {
    borderRadius: '5px',
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },

  listCustomIcon: {
    fontSize: '1.8rem',
    height: '30px',
  },
  menuText: {
    marginLeft: '10px',
    opacity: 1,
    color: '#fff',
  },
  menuTextClose: {
    maxWidth: 0,
    opacity: 0,
    '& > p': {
      width: 0,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#fff',
  },
  paddingNone: {
    backgroundColor: '#FFF',
    padding: 0,
    borderRadius: 4,
  },
  dotRoot: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: -20,
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    filter: 'brightness(0) invert(1)',
  },
  iconMenu: {
    color: '#fff',
    opacity: 0,
  },
  iconShow: {
    opacity: 1,
  },
  popper: {
    zIndex: 9999,
    marginLeft: 20,
    borderRadius: 5,
  },
  boxShadow: {
    padding: 8,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
  },
}));

const NavItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const { item, level, drawerOpen } = props;
  const app = { ...item, app_id: item.id };
  const { selectedApp } = useSelector((state) => state.app);

  const handleProjectClick = async (project) => {
    dispatch({ type: SELECTED_APP_CHANGE, app });
    const newProjectList = item.projects.map((projectselected) => {
      return {
        ...projectselected,
        selected: projectselected.id === project.id ? true : false,
      };
    });
    dispatch({ type: PROJECT_CHANGE, projects: newProjectList });
    if (app.is_sub) {
      const items = item.projects.map((item) => {
        return mapping(item);
      });

      dispatch({
        type: FOLDER_CHANGE,
        folder: items,
      });
      dispatch({ type: SELECTED_FOLDER_CHANGE, selectedFolder: mapping(project) });
    }
  };

  const mapping = (item) => {
    return {
      name: item.project_name,
      action: item.action,
      id: item.id,
      icon: item.icon,
    };
  };

  const popupState = usePopupState({ variant: 'popper', popupId: app.id });

  return (
    <>
      <Tooltip title={item.app_name} placement="bottom">
        <ListItem
          {...bindHover(popupState)}
          disabled={app.disabled}
          className={clsx(classes.listItemroot, classes.listItem)}
          selected={selectedApp.id === app.id}
          component={Link}
          to={app.url || '#'}
          style={{ height: 40 }}
          button
        >
          <img className={classes.icon} src={app.icon || iconWhite} alt="icon" />
          <ListItemText
            className={drawerOpen ? classes.menuText : classes.menuTextClose}
            primary={
              <Typography variant={customization.isOpen === item.id ? 'subtitle1' : 'body1'} className={classes.noWrap}>
                {item.app_name}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )
            }
          />
          {/* <div className={classes.dotRoot}>
            <MoreVertOutlined
              className={clsx(classes.iconMenu, {
                [classes.iconShow]: app.projects?.length > 0,
              })}
            />
          </div> */}
        </ListItem>
      </Tooltip>
      <Popper {...bindPopper(popupState)} placement="right-start" transition className={classes.popper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350} className={classes.paddingNone}>
            <Paper>
              {app.projects?.length > 0 && (
                <ArrowLeft
                  style={{
                    marginLeft: -28,
                    color: '#fff',
                    marginTop: -5,
                    height: 50,
                    width: 50,
                    position: 'fixed',
                  }}
                />
              )}
              <List
                component="nav"
                // className={classes.paddingNone}
                className={clsx(classes.paddingNone, {
                  [classes.boxShadow]: app.projects?.length > 0,
                })}
              >
                {item.projects?.map((project, index) => (
                  <ListItem
                    key={index}
                    button
                    component={NavLink}
                    to="/dashboard/default"
                    onClick={() => handleProjectClick(project)}
                    className={clsx(classes.listItemroot, classes.listItem)}
                  >
                    <img className={classes.icon} src={project.icon || iconDefault} alt="icon" />
                    <ListItemText
                      primary={
                        <Typography
                          variant={customization.isOpen === item.id ? 'subtitle1' : 'body1'}
                          className={classes.noWrap}
                          style={{ marginLeft: 10, color: theme.palette.primary.main }}
                        >
                          {project.project_name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default NavItem;
