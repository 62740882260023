import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FOLDER_CHANGE, PROJECT_CHANGE, SELECTED_APP_CHANGE } from './../../../store/actions';
import { ChatOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1.25),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  logoSize: {
    width: '100%',
    height: '40px',
    objectFit: 'contain',
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    dispatch({
      type: SELECTED_APP_CHANGE,
      app: {},
    });
    dispatch({
      type: PROJECT_CHANGE,
      projects: [],
    });
    dispatch({
      type: FOLDER_CHANGE,
      folder: [],
    });
  };

  return (
    <React.Fragment>
      <Hidden smDown>
        <Link to="/dashboard/app" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src={logo} alt="Logo" className={classes.logoSize} />
        </Link>
      </Hidden>
      <div className={classes.grow} />
      <Link to="/dashboard/chat">
        <ChatOutlined color="primary" />
      </Link>
      <NotificationSection />
      <ProfileSection />
    </React.Fragment>
  );
};

export default Header;
