import React from 'react';
import NavItem from './../NavItem';

const NavGroup = (props) => {
  const { item, drawerToggle, drawerOpen } = props;

  if (item.projects?.length === 0) return;

  return (
    <NavItem item={item} level={1} drawerToggle={drawerToggle} drawerOpen={drawerOpen} />
  );
};

export default NavGroup;
