import { makeStyles } from '@material-ui/core';

export const useDialog50Styles = makeStyles((theme) => ({
  tableUserAvatar: {
    height: '50px',
    width: '50px',
    objectFit: 'cover',
    boxShadow: 'rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px',
    borderRadius: '4px',
  },
  dialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        width: '50%',
        maxWidth: '50%',
        maxHeight: '100%',
      },
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  dialogAction: {
    justifyContent: 'space-between',
  },
  itemFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '12px',
    cursor: 'pointer',
  },
  fileName: {
    textAlign: 'center',
    color: '#000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '& > img': {
      marginRight: '4px',
    },
  },
  iconUpload: {
    width: '50px',
    height: '50px',
  },
  history: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '23px',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },

  timeline: {
    padding: 0,
    margin: 0,
    '& .MuiTimelineOppositeContent-root': {
      flex: 'inherit',
    },
  },
}));

export const useDialog100Styles = makeStyles((theme) => ({
  tableUserAvatar: {
    height: '50px',
    width: '50px',
    objectFit: 'cover',
    boxShadow: 'rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px',
    borderRadius: '4px',
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  dialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
  dialogButton: {
    width: '100%',
    marginTop: '10%',
    display: 'flex-end',
  },
  table: {
    minWidth: 450,
    borderBottom: 'none',
    '& > tbody > tr:hover': {
      backgroundColor: theme.palette.secondary.light + ' !important',
    },
    // '& > tbody > tr:hover td': {
    //   color: '#FFF',
    // },
    // '& > tbody > tr:hover td span': {
    //   color: '#FFF',
    // },
    // '& > tbody > tr:hover td svg': {
    //   fill: '#FFF',
    // },
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
  avatar: {
    width: 72,
    height: 72,
  },
  flexColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },

  list: {
    border: '1px solid #A2ACB4',
    borderRadius: 4,
    padding: 0,
  },
  name: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  success: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  chip: {
    background: theme.palette.bg.secondary,
  },
}));
export const useDialog100HoriStyles = makeStyles((theme) => ({
  tableUserAvatar: {
    height: '50px',
    width: '50px',
    objectFit: 'cover',
    boxShadow: 'rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px',
    borderRadius: '4px',
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  dialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0 0 !important',
      '& .MuiBox-root': {
        height: '100%',
        width: '100%',
      },
    },
  },
  dialogButton: {
    width: '100%',
    marginTop: '10%',
    display: 'flex-end',
  },
  table: {
    minWidth: 450,
    borderBottom: 'none',
    '& > tbody > tr:hover': {
      backgroundColor: theme.palette.secondary.light + ' !important',
    },
  },
  dialogAction: {
    justifyContent: 'space-between',
    marginLeft: 100,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
  avatar: {
    width: 72,
    height: 72,
  },
  flexColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  tabPanel: {
    '& .MuiTab-wrapper': {
      alignItems: 'start',
      justifyContent: 'start',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#00579c',
      backgroundColor: 'rgb(229, 232, 235) !important',
    },
  },
  tab: {
    '& .MuiBox-root': {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
    },
    width: '100%',
  },
  list: {
    border: '1px solid #A2ACB4',
    borderRadius: 4,
    padding: 0,
  },
  name: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  success: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  chip: {
    background: theme.palette.bg.secondary,
  },
}));
