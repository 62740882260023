import React, { useState } from 'react';
import {
  makeStyles,
  Fade,
  ClickAwayListener,
  Paper,
  Popper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  withStyles,
  Badge,
} from '@material-ui/core';
import MeetingRoomTwoToneIcon from '@material-ui/icons/MeetingRoomTwoTone';
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';
import useAuth from '../../../../hooks/useAuth';
import ProfileModal from './profile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '250px',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
    borderRadius: '10px',
  },
  subHeader: {
    backgroundColor: theme.palette.grey.A400,
    color: theme.palette.common.white,
    padding: '5px 15px',
  },
  username: {
    color: theme.palette.common.black,
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '35px',
    },
  },
  avatar: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ProfileSection = () => {
  const classes = useStyles();
  const [selectedIndex] = React.useState(1);
  const { logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const user = JSON.parse(window.localStorage.getItem('user'));
  const [account, setAccount] = useState(user?.account || {});
  const [openDialog, setOpenDialog] = useState(false);
  const prevOpen = React.useRef(open);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user'));
    setAccount(user?.account || {});
  }, [window.localStorage.getItem('user'), setAccount]);

  return (
    <React.Fragment>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar
          src={account?.avatar_url}
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls={open ? 'menu-list-grow' : undefined}
          alt="avatar"
          className={classes.avatar}
        />{' '}
      </StyledBadge>

      <div style={{ display: 'none' }}>
        <ProfileModal openDialog={openDialog} selectedDocument={account} setOpenDialog={setOpenDialog} />
      </div>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: {
            offset: {
              enable: true,
              offset: '0px, 10px',
            },
            preventOverflow: {
              padding: 0,
            },
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List component="nav" className={classes.root}>
                  <ListItem button onClick={() => setOpenDialog(true)}>
                    <ListItemIcon>
                      <PersonTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        account
                          ? account.fullname === '' || !account.fullname
                            ? 'No Name'
                            : account.fullname
                          : 'No Name'
                      }
                    />
                  </ListItem>
                  <ListItem button selected={selectedIndex === 4} onClick={handleLogout}>
                    <ListItemIcon>
                      <MeetingRoomTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default ProfileSection;
