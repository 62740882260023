import {
  Card,
  Grid,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../services/axios';
import { style, useStyles } from '../../ProcessTable/style';
import { Pagination } from '@material-ui/lab';
async function getDocuments(url, page, no_item_per_page, queries) {
  return await axiosInstance
    .post(url, {
      page: page,
      no_item_per_page: no_item_per_page,
      ...queries,
      order_by: '',
      order_type: '',
      search_text: '',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list, total_page, total_item } = response.data;
        return { list, total_page, total_item };
      }
    });
}
export default function GeneralTable(props) {
  const classes = useStyles();
  const { url, queries, reload, removeObject, setReloadList } = props;
  const no_item_per_page = 10;
  const [openRemove, setOpenRemove] = useState({ open: false, object: {} });
  const [documents, setDocumentTable] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const fetchDocument = async (page) => {
    const data = await getDocuments(url, page, no_item_per_page, queries);
    setDocumentTable(data.list);
    setCount(data.total_item);
  };
  const handleRemove = (row) => {
    removeObject(row);
  };
  const handleChangePage = (event, newPage) => {
    let page = newPage;
    setPage(page);
    fetchDocument(page);
  };

  useEffect(() => {
    if (!reload) return;
    fetchDocument(page);
    setReloadList(false);
  }, [reload]);

  return (
    <React.Fragment>
      {openRemove.open && (
        <>
          <Dialog
            open={openRemove.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ minHeight: 300, maxHeight: 300 }}
          >
            <DialogTitle>{'Xác nhận xoá người dùng?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Xác nhận xoá người dùng email: {openRemove?.object.email_address}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button variant="outlined" onClick={() => setOpenRemove({ open: false, object: {} })}>
                    Đóng
                  </Button>
                </Grid>
                <Grid item>
                  {' '}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleRemove(openRemove.object);
                      setOpenRemove({ open: false, object: {} });
                    }}
                  >
                    Xác nhận
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.rootmini}>
            <TableContainer>
              <Table className={classes.tablemini} stickyHeader size={'medium'}>
                <TableHead>
                  <TableCell align="left">Ảnh</TableCell>
                  <TableCell align="left">Họ tên</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left"></TableCell>
                </TableHead>
                <TableBody>
                  {documents?.map((row, index) => {
                    return (
                      <TableRow key={index} hover>
                        <TableCell align="left">
                          <img alt="" src={row.image_url} style={style.tableUserAvatar} />
                        </TableCell>
                        <TableCell align="left">{row.full_name}</TableCell>
                        <TableCell align="left">{row.email_address}</TableCell>

                        <TableCell align="left">
                          <IconButton
                            onClick={() => {
                              setOpenRemove({ open: true, object: row });
                            }}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {Math.ceil(count / no_item_per_page) > 1 && (
              <Pagination
                color="primary"
                className={classes.pagination}
                variant="outlined"
                shape="rounded"
                count={Math.ceil(count / no_item_per_page)}
                page={page}
                onChange={handleChangePage}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
